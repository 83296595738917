import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DeclineSignupRequest } from '../../models/decline-signup-request';
import { TitleService } from 'src/app/shared/services/title.service';

@Component({
  selector: 'app-decline-signup-request',
  templateUrl: './decline-signup-request.component.html',
  styleUrls: ['./decline-signup-request.component.css']
})
export class DeclineSignupRequestComponent implements OnInit,OnDestroy {

  message !: string;
  sub: any;
  email !: string;
  userId !:string;
  customerId !: number;
  displayModal !: boolean;
  responseMessage !: string;
  isDisplayErrorModal!: boolean;
  constructor(private route: ActivatedRoute,private titleService:TitleService, private authservice:AuthService,private messageService: MessageService,
    private router: Router, private confirmationService: ConfirmationService ) {}

  ngOnInit() {
    this.titleService.setTitle("Neka registreringsförfrågan");
    setTimeout(() => {
      this.displayModal =true;
    }, 1000);
   
    this.sub = this.route.params.subscribe(params => {
       this.email = params['email']; 
       this.userId = params['userId']; 
       this.customerId =+params['customerId'] 
    });
    this.message="";
  }

  submit(){
    this.confirmationService.confirm({
      message: 'Är du säker på att du vill neka denna förfrågan?',
      header: 'Neka förfrågan',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.acceptDecline();
          //this.msgs = [{severity:'info', summary:'Confirmed', detail:'You have accepted'}];
      },
      reject: () => {
          //this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
      }
  });
  }

  acceptDecline() {
    let declineRequest= new DeclineSignupRequest();
    declineRequest.declineMessage= this.message;
    declineRequest.userId = this.userId;
    declineRequest.customerId= this.customerId;

    this.authservice.declineSignupRequest(declineRequest).subscribe(res=>{
      this.messageService.add({   severity: 'info', summary: 'Framgång', detail: res.message });
      setTimeout (()=>this.closeDialog(), 1500);
    },   
     error => {      
      this.messageService.add({ severity: 'error', summary: 'Fel', detail: error.error.message });
     }
    );
  }

  closeDialog(){
    this.displayModal =false;
    window.self.close();   
  }

  closeErrorDialog(){
    this.isDisplayErrorModal =false;
    window.self.close();   
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
