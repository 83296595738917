import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CustomerType } from '../../models/customer-type';
import { SignUpData } from '../../models/sign-up.data';
import { SignUpService } from '../../services/sign-up.service';

@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.css'],
})
export class CustomerDetailsComponent implements OnInit {
  signupData!: SignUpData;

  submitted!: boolean;

  customerTypes!: any[];
  emaiExist: boolean;
  customerDetailsForm: FormGroup<{
    customerType: any;
    ssnOrg: any;
    email: any;
    phone: any;
  }>;

  constructor(
    private router: Router,
    private signupService: SignUpService,
    private authService: AuthService
  ) {
    this.signupData = this.router.getCurrentNavigation()?.extras
      .state as SignUpData;
    if (this.signupData == null) {
      this.signupData = new SignUpData();
      this.signupData.phone = '';    
    }
    this.signupData.customerMappings = [];
  }

  ngOnInit(): void {
    this.customerDetailsForm = new FormGroup({
      customerType: new FormControl(this.signupData.customerType, [
        Validators.required,
      ]),
      ssnOrg: new FormControl(this.signupData.ssnOrg, [Validators.required]),
      email: new FormControl(this.signupData.email, [
        Validators.required,
        Validators.email,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
      ]),
      phone: new FormControl(this.signupData.phone,[Validators.required,  Validators.minLength(10)]),
    });

    this.customerDetailsForm
      .get('customerType')
      .valueChanges.subscribe((val) => {
        this.signupData.customerType = val as CustomerType;
      });

    this.signupService.getCustomerTypes().subscribe((result) => {
      this.customerTypes = this.signupService.translateCustomerTypes(result);
      if (this.signupData.customerType != null){
        this.customerType.setValue(this.signupData.customerType);
      }
      else{
        this.customerType.setValue(this.customerTypes[1]);
      }

    });
  }

  get email() {
    return this.customerDetailsForm.get('email');
  }
  get ssnOrg() {
    return this.customerDetailsForm.get('ssnOrg');
  }
  get customerType() {
    return this.customerDetailsForm.get('customerType');
  }

  get phone() {
    return this.customerDetailsForm.get('phone');
  }

  previousPage() {
    this.router.navigate(['login'], {
      state: this.signupData,
    });
  }

  mobileNumber(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode != 43 && charCode != 32) {
      return false;
    }
    return true;
  }
  
  nextPage(): void {
    if (this.customerDetailsForm.valid) {
      this.signupData.email = this.email.value as string;
      this.authService
        .validateEmai(this.signupData.email)
        .subscribe((result) => {
          if (result) {
            this.signupData.phone= (this.phone.value as string).toString();
            this.signupData.ssnOrg = this.ssnOrg.value as string;
            this.router.navigate(['sign-up/customer-mapping'], {
              state: this.signupData,
            });
          } else {
            this.emaiExist = true;
            this.email.setErrors({ incorrect: true });
          }
        });

      return;
    }

    this.submitted = true;
  }

  convertToLowercase() {
    this.email.setValue(this.email.value.toString().toLowerCase());
  }
}
