<div class="container-fluid d-flex align-items-stretch login-bg">
    <div class="login-container d-flex align-items-center w-100 justify-content-center">
        <div class="row w-50 bg-white shadow-sm">
            <div
                class="col-12 col-sx-12 col-sm-12 col-md-6 login-wrapper loginbg-img d-flex align-items-end text-end p-5">
                <div> <img src="assets/imgs/vinning_white.png" alt="" class="img-fluid fadeIn">
                    <p class="text-white">Vi rustar en traditionell bransch inom järn- och metallåtervinning för
                        morgondagens behov</p>
                </div>
            </div>
            <div class="col-12 col-sx-12 col-sm-12 col-md-6 login-wrapper p-5 d-flex align-items-center">
                <div class=" w-100">
                    <h6 class="mb-4 fadeIn">Neka registreringsförfrågan</h6>
                    <div class="form-group mb-3">
                        <label for="exampleInputPassword1" class="fw-bold"> Email: </label> {{email}}
                    </div>

                    <div class="form-group mb-3">
                        <label for="exampleInputPassword1" class="fw-bold label-required">Anledning</label>:
                        <textarea [maxlength]="1000" [(ngModel)]="message" [rows]="10" [cols]="25"
                            pInputTextarea></textarea>
                    </div>
                    <button [disabled]="!message || message.trim().length ==0" (click)="submit()"
                        icon="pi pi-angle-right" iconPos="right" type="button"
                        class="btn btn-dark w-100 p-2 fw-bold">Neka</button>

                    <p-confirmDialog [acceptLabel]="'Ja'"
                    [rejectLabel]="'Nej'" rejectButtonStyleClass="p-button-outlined" acceptButtonStyleClass="p-button-warning" icon="pi pi-exclamation-triangle" [closable]="false"></p-confirmDialog>
                </div>
            </div>
        </div>
    </div>
</div>