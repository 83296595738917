import { AuthService } from '../../../../shared/services/auth.service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { Store } from '@ngrx/store';
import { AuthStatus, SelectedProfile } from '../../../../shared/models/state.model';
import { AppRouterPath } from 'src/app/shared/constants';
import { CustomerService } from '../../../../shared/services/customer.service';
import { CompanyService } from '../../../../shared/services/company.service';
import { Customer } from '../../../../shared/models/customer.model';
import { Company } from '../../../../shared/models/company.model';
import { reviseLoginFlowStatus } from 'src/app/store/actions/app.auth.action';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['../layout/layout.component.scss']
})
export class HeaderComponent {
  items: MenuItem[]= new Array<MenuItem>();

  customerName: string;
  companyName: string;

  constructor(
    private authService: AuthService,
    private companyService: CompanyService,
    private customerService: CustomerService,
    private router: Router,
    private store: Store<AuthStatus>) {}

  ngOnInit() {
        this.items = [{
          label: 'Alternativ',
          items: [{
              label: 'Byt profil',
              icon: 'pi pi-refresh',
              command: () => {
                this.store.dispatch(reviseLoginFlowStatus(false));
                this.router.navigateByUrl(`/${AppRouterPath.selectCustomer}`);
              }
          },
          {
              label: 'Logga ut',
              icon: 'pi pi-fw pi-power-off',
              command: () => {
                this.authService.logout();
              }
          }
          ]}
      ];

      this.store.select(state => state['authStatus']['selectedProfile']).subscribe((profile: SelectedProfile) => {
        if(profile.customerId){
          this.customerService.getById(profile.customerId).subscribe({
            next: (customer: Customer) => {
              this.customerName = customer.name;
            }
          });
        }
        
        if(profile.companyId){
          this.companyService.getById(profile.companyId).subscribe({
            next: (company: Company) => {
              this.companyName = company.companyName;
            }
          });
        }
      })
  }

  goToDashboard = () => {
    this.router.navigateByUrl(`/${AppRouterPath.dashboard}`);
  }
}
