<div class="container-fluid d-flex align-items-stretch login-bg">
    <div class="login-container d-flex align-items-center w-100 justify-content-center">
        <div class="row w-50 bg-white shadow-sm">
            <div class="col-12 col-sx-12 col-sm-12 col-md-6 login-wrapper loginbg-img d-flex align-items-end text-end p-5">
               <div> <img src="assets/imgs/vinning_white.png" alt="" class="img-fluid fadeIn">
                <p class="text-white">Vi rustar en traditionell bransch inom järn- och metallåtervinning för morgondagens behov</p></div>
            </div>
            <div class="col-12 col-sx-12 col-sm-12 col-md-6 login-wrapper p-5 d-flex align-items-center" >
               <div class=" w-100"> <h2 class="mb-4 fadeIn">Återställ lösenord</h2>
                <div *ngIf="!requestSent && !requestFailed">
                  <div class="form-group mb-3">
                    <label for="email" class="fw-bold">Email</label>
                    <input type="text" pInputText [(ngModel)]="identifier" id="email" aria-describedby="SSN-ORG" placeholder="Ange email">
                    <small *ngIf="!validEmailInput()" id="confirmPasswordHelp" class="p-error">Vänlig ange en giltig email!</small>
                  </div>
                  <button pButton [disabled]="invalidInput()" (click)="onSubmit($event)" type="button" class="btn btn-dark w-100 p-2 fw-bold ">Skicka in</button>
                  <div>
                      <p class="small mt-3">
                        Skicka förfrågan, intruktioner hur du återställer ditt lösenord fås på angiven email
                      </p>
                  </div>
                </div>
                <div *ngIf="requestSent">
                  <p>Instruktion hur du återställer dit lösenord har skickats till <strong>{{identifier}}</strong></p>
                </div>

                <div *ngIf="requestFailed">
                  <p><strong>{{identifier}}</strong> är inte en registrerad användare</p>
                </div>
            </div>
          </div>
        </div>
    </div>
</div>
