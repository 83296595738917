import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { SignUpCustomerRequest } from '../../models/signup-customer-request';
import { SignUpService } from '../../services/sign-up.service';

@Component({
  selector: 'app-sign-up-requests',
  templateUrl: './sign-up-requests.component.html',
  styleUrls: ['./sign-up-requests.component.css'],
})
export class SignUpRequestsComponent implements OnInit {
  signupRequstCustomers!: SignUpCustomerRequest[];
  emailContent!: string;
  displayEmailConent!: boolean;

  constructor(
    private router: Router,
    private authService: AuthService,
    private signupSerice: SignUpService
  ) {}

  ngOnInit(): void {
    this.signupSerice
      .getCustomerSignUpRequests()
      .subscribe((result) => (this.signupRequstCustomers = result));
  }

  getEmailContet(data: SignUpCustomerRequest) {
    this.authService.getEmailContent(data).subscribe((result) => {
      this.emailContent = result;
      this.displayEmailConent = true;
    });
  }
}
