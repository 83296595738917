import { createReducer, on } from "@ngrx/store";
import { StorageKey } from "src/app/shared/constants";
import { appLocalStorage } from "src/app/shared/helpers/local-storage.helper";
import { AuthStatus, SelectedProfile } from "src/app/shared/models/state.model";
import { persistProfile, rehydrateProfile, resetAuthStatus, reviseLoginFlowStatus, reviseSelectedProfile } from "../actions/app.auth.action";


export const initialAuthState = {
  loginFlowCompleted: false,
  selectedProfile: {} as SelectedProfile
} as AuthStatus;

export const authStatusReducer = createReducer(
  initialAuthState,

  on(resetAuthStatus, () => (initialAuthState)),

  on(reviseLoginFlowStatus, (state, {loginFlowCompleted}) => ({
    loginFlowCompleted, selectedProfile: state.selectedProfile
  })),

  on(reviseSelectedProfile, (state, {selectedProfile}) => ({
    loginFlowCompleted: state.loginFlowCompleted, selectedProfile
  })),

  on(persistProfile, (state) => {
    appLocalStorage.save(StorageKey.PROFILE, state.selectedProfile);
    return state;
  }),

  on(rehydrateProfile, (_state) => {
    const selectedProfile: SelectedProfile = appLocalStorage.get(StorageKey.PROFILE) as SelectedProfile;
    return selectedProfile? {
      loginFlowCompleted: true,
      selectedProfile
    }: initialAuthState;
  }),
);
