import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/shared/helpers/auth.guard';
import { AppRouterPath } from '../../shared/constants';
import { DashboardComponent } from './components/dashboard.component';

const routes: Routes = [{ path: AppRouterPath.dashboard, component: DashboardComponent, canActivate: [AuthGuard]}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
