
<div class="row">
  <div class="col-12 col-lg-6"><p-chart type="pie" [data]="data" [options]="chartOptions" width="auto"></p-chart>
  </div>
  <div class="col-12 col-lg-6 d-flex align-items-center justify-content-center"><p-table
    #dt1
    [value]="tabledata"
    selectionMode="single"
    dataKey="id"
    styleClass="mt-2 p-datatable-gridlines p-datatable-md"
    [rowHover]="true"
    stateStorage="local"
    stateKey="statedemo-local">
    <ng-template pTemplate="header">
      <tr>
        <th>
          Undergrupp
        </th>
        <th class="text-end">
          Vikt <small>(kg)</small>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-element>
      <tr>
        <td>{{element.articleSubGroupName}}</td>
        <td class="text-end">{{element.sum_Weight | number: '1.0-0':'lang'}}</td>
      </tr>
    </ng-template>
    <ng-template  pTemplate="footer">
      <tr>
          <td class="text-left">Summa</td>
          <td class="text-end">{{total | number: '1.0-0':'lang'}}</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="2" class="text-center">Inga rader funna.</td>
      </tr>
    </ng-template>
    </p-table></div>
</div>

