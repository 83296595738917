import { Component, Inject, Input, LOCALE_ID, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TreeNode } from 'primeng/api';
import { Table } from 'primeng/table';
import { PagedResponse } from 'src/app/shared/models/response.model';
import { AuthStatus, SelectedProfile } from 'src/app/shared/models/state.model';
import {
  FilteredWasteCodeRequest,
  FilteredWasteCodeTableRequest,
  WasteCodeElement,
  WasteCodeElementsTotal
} from '../models/waste-code-grid.model';
import { WasteCodeService } from '../services/waste-code.service';
import { lastValueFrom } from 'rxjs';
import { CommonService } from 'src/app/shared/services/common.service';
import { CostCenter, Yard } from 'src/app/shared/models/common.model';
import { CustomerType } from 'src/app/shared/constants';
import { DownloadService } from 'src/app/shared/services/download-service';
import { TitleService } from 'src/app/shared/services/title.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-waste-code',
  templateUrl: './waste-code.component.html',
  styleUrls: ['./waste-code.component.css'],
})
export class WasteCodeComponent {

  selectedCountry: any;
  selectedCities: string = '';
  @ViewChild('dt') dt: Table | undefined;
  date3: string = '';
  selectedProfile: SelectedProfile;
  selectedYards: Yard[];
  selectedCostCenters: CostCenter[];

  applyFilterGlobal($event: any, stringVal: any) {
    this.dt!.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  commonFilters: FilteredWasteCodeRequest = {
    startDate: null,
    endDate: null,
 
    costCenterIds: '',
    yardIds: '',
  };
  costCenters: CostCenter[];
  yards: Yard[];

  @Input() ewcTblFilters: FilteredWasteCodeTableRequest;
  @Input() rdTblFilters: FilteredWasteCodeTableRequest ;
  selectedCustomer1: any;

  ewcGridData: PagedResponse<TreeNode[]> = {
    results: [],
    pageNumber: 0,
    pageSize: 0,
    total: 0,
    orderBy: 'WasteCode',
    sortOrder: 'ASC',
    totalNumberOfRecords: 0,
  };

  rdGridData: PagedResponse<TreeNode[]> = {
    results: [],
    pageNumber: 0,
    pageSize: 0,
    total: 0,
    orderBy: 'WasteCode',
    sortOrder: 'ASC',
    totalNumberOfRecords: 0,
  };

  ewcGridTotals: WasteCodeElementsTotal = {
    weight: 0,
  };

  rdGridTotals: WasteCodeElementsTotal = {
    weight: 0,
  };
  fromDate: any;
  toDate: any;
  rdPageNumber = 0;
  ewcPageNumber = 0;

  constructor(
    public wasteCodeService: WasteCodeService,
    private store: Store<AuthStatus>,
    private commonService: CommonService,
    private downloadService:DownloadService,
    private titleService:TitleService,
    @Inject(LOCALE_ID) private locale: string
  ) {}

  clear(){
    this.fromDate = null;
    this.toDate = null;
    this.selectedYards = new Array<Yard>();
    this.rdTblFilters.articleNumber = null;
    this.rdTblFilters.wasteCode = null;
    this.ewcTblFilters.articleNumber = null;
    this.ewcTblFilters.wasteCode = null;
    this.fetchTableData();
    this.setDefaultFilteration();
  }

  setDefaultFilteration(){
    this.ewcTblFilters = {
      articleNumber: '',
      wasteCode: null,
      pageSize: 10,
      page: 1,
      orderBy: 'WasteCode',
      sortOrder: 'ASC',
      smartCompanyId: 0,
      smartCustomerId: 0,
      isEwcWasteCodeTable:true,
      costCenterIds:null,
      yardIds:null,
      startDate: null,
      endDate: null,
    orderByTblVal:1
    };
  
    this.rdTblFilters= {
      articleNumber: '',
      wasteCode: null,
      pageSize: 10,
      page: 1,
      orderBy: 'WasteCode',
      sortOrder: 'ASC',
      smartCompanyId: 0,
      smartCustomerId: 0,
      isEwcWasteCodeTable:false,
      costCenterIds:null,
      yardIds:null,
      startDate: null,
      endDate: null,
      orderByTblVal:1
    };
  }

  async ngOnInit() {
      this.setDefaultFilteration();
    this.titleService.setTitle("Avfallskoder");
    this.store
      .select(
        (state) =>
          (this.selectedProfile = state['authStatus']['selectedProfile'])
      )
      .subscribe();
      await this.fetchYards();
      await this.fetchCostCenters();
      this.fetchTableData();
    
  }

  fetchTableData = () => {
    this.getEWCWasteCodePagedResults(this.ewcTblFilters);
    this.getRDWasteCodePaged(this.rdTblFilters);
  };

  get isIndividualCustomer():boolean{
    if(this.selectedProfile.customerTypeId == CustomerType.Individual){
      return true;
    }
    return false;
  }

  onFromDateChanged = () => {
    this.ewcTblFilters.page = 1;
    this.rdTblFilters.page = 1;
    this.fetchTableData();
  };

  onToDateChanged = () => {
    this.ewcTblFilters.page = 1;
    this.rdTblFilters.page = 1;
    this.fetchTableData();
  };
 
  getEWCWasteCodePagedResults(filters:FilteredWasteCodeTableRequest) {
    this.wasteCodeService
      .getEWCWasteCodePagedResults(this.setFilterData(filters))
      .subscribe({
        next: (data: PagedResponse<WasteCodeElement[]>) => {
          this.bindDataToEWCGrid(data);
          this.ewcPageNumber = data.pageNumber - 1;
          this.calculateTableTotal(data, true);
        },
      });
  }

  export(isEWCWasteCodeGrid:boolean){
    if(isEWCWasteCodeGrid){
      this.wasteCodeService.exportEWCWasteCodeView(this.setFilterData(this.ewcTblFilters)).subscribe({
        next: (data: any) => {
          var base64 = this.downloadService.base64ToArrayBuffer(data);
          this.downloadService.saveByteArray(
            'Export Vinning kundportal - EWC avfallskod',
            base64
          );
        },
      });
    }else{
      this.wasteCodeService.exportRDWasteCodeView(this.setFilterData(this.rdTblFilters)).subscribe({
        next: (data: any) => {
          var base64 = this.downloadService.base64ToArrayBuffer(data);
          this.downloadService.saveByteArray(
            'Export Vinning kundportal - RD avfallskod',
            base64
          );
        },
      });
    }
  
  }
 
  onYardSelectionChange = () => {
    this.ewcTblFilters.page = 1;
    this.rdTblFilters.page = 1;
    this.fetchTableData();
  };

  onCostCenterSelectionChange = () => {
    this.ewcTblFilters.page = 1;
    this.rdTblFilters.page = 1;
    this.fetchTableData();
  };

  bindDataToEWCGrid(data: PagedResponse<any[]>){
    this.ewcGridData.results=[];
    this.ewcGridData = data;
    this.ewcGridData.totalNumberOfRecords = data.totalNumberOfRecords;
  }

  getRDWasteCodePaged(filters:FilteredWasteCodeTableRequest) {
    this.wasteCodeService
      .getRDWasteCodePagedResults(this.setFilterData(filters))
      .subscribe({
        next: (data: PagedResponse<WasteCodeElement[]>) => {
         this.rdPageNumber = data.pageNumber - 1;
         this.bindDataToRDGrid(data);
         this.calculateTableTotal(data, false);
        },
      });
  }

  bindDataToRDGrid(data: PagedResponse<any[]>){
    this.rdGridData.results=[];
    this.rdGridData = data;
    this.rdGridData.totalNumberOfRecords = data.totalNumberOfRecords;
  }

  calculateTableTotal(
    data: PagedResponse<any[]>,
    isEwcWasteCodeGrid: boolean
  ) {
    if (isEwcWasteCodeGrid) {
      this.ewcGridTotals.weight = 0;
      for (var res of data.results) {
        this.ewcGridTotals.weight = this.ewcGridTotals.weight + res.data.sum_Weight;
      }
      this.ewcGridTotals.weight = Math.round(this.ewcGridTotals.weight); 
    } else {
      this.rdGridTotals.weight = 0;
      for (var res of data.results) {
        this.rdGridTotals.weight = this.rdGridTotals.weight + res.data.sum_Weight;
      }
      this.rdGridTotals.weight = Math.round(this.rdGridTotals.weight); 
    }
  }

  fetchYards() {
    this.commonService.getYardsByCustomerIdAndCompanyId(
      this.selectedProfile.customerId,
      this.selectedProfile.companyId
    ).subscribe((data) => {
      this.yards = data;
    });
  }

  fetchCostCenters() {
    this.commonService.getCostCentersByCustomerIdAndCompanyId(
      this.selectedProfile.customerId,
      this.selectedProfile.companyId
    ).subscribe((data) => {
      this.costCenters = data;
    });
  }

  refreshTable(value:FilteredWasteCodeTableRequest){
    if(value.isEwcWasteCodeTable){
      this.getEWCWasteCodePagedResults(value);
    }else{
      this.getRDWasteCodePaged(value);
    }
  }

  setFilterData(filters:FilteredWasteCodeTableRequest): FilteredWasteCodeTableRequest {
    filters.smartCompanyId = Number(this.selectedProfile.companyId);
    filters.smartCustomerId = Number(this.selectedProfile.customerId);

    if(this.selectedYards && this.selectedYards.length > 0){
      filters.yardIds = this.selectedYards.map((elem) => elem.id).join(',');
    }else{
      filters.yardIds=null;
    }

    if(this.fromDate){
      filters.startDate = formatDate(new Date(this.fromDate),'yyyy-MM-dd',this.locale);
    }

    if(this.toDate){
      filters.endDate = formatDate(new Date(this.toDate),'yyyy-MM-dd',this.locale);
    }

    if(this.selectedCostCenters && this.selectedCostCenters.length > 0){
      filters.costCenterIds = this.selectedCostCenters
    .map((elem) => elem.id)
    .join(',');
    }else{
      filters.costCenterIds=null;
    }
  
    return filters;
  }
}
