import { Component, ViewChild } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { PagedResponse } from 'src/app/shared/models/response.model';
import {
  FilteredTransactionTableRequest,
  TransactionElement,
  TransactionElementsTotal,
  TransactionValueSummeryData,
  TransactionWeightSummeryData,
} from '../models/transaction-summery-data';
import { AuthStatus, SelectedProfile } from 'src/app/shared/models/state.model';
import { CostCenter, Yard } from 'src/app/shared/models/common.model';
import { CustomerType } from 'src/app/shared/constants';
import { TransactionService } from '../services/transaction.service';
import { Store } from '@ngrx/store';
import { CommonService } from 'src/app/shared/services/common.service';
import { DownloadService } from 'src/app/shared/services/download-service';
import { lastValueFrom } from 'rxjs';
import { DatePipe } from '@angular/common';
import { Article } from 'src/app/shared/models/article.model';
import { TitleService } from 'src/app/shared/services/title.service';
import { ChartColourCodes } from 'src/app/shared/models/chart-colour-codes';

@Component({
  selector: 'app-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.css'],
})
export class TransactionComponent {
  fromDate: Date;
  toDate: Date;

  gridData: PagedResponse<TreeNode[]> = {
    results: [],
    pageNumber: 0,
    pageSize: 0,
    total: 0,
    orderBy: '',
    sortOrder: '',
    totalNumberOfRecords: 0,
  };

  sortingColYardName: string = 'yardName';
  sortingColCostCenter: string = 'costCenter';
  sortingColPurchaseNumber: string = 'purchaseNumber';
  sortingColPurchaseDate: string = 'purchaseDate';

  summaryTotals: TransactionElementsTotal = {
    weight: 0,
    vatExclusiveAmount: 0,
  };

  tblSortOrder = -1;
  pageNumber = 0;
  TblFilters: FilteredTransactionTableRequest;
  selectedProfile: SelectedProfile;
  selectedYards: Yard[];
  selectedCostCenters: CostCenter[];
  costCenters: CostCenter[];
  yards: Yard[];
  articles: Article[];
  selectedArticles: Article[];
  visibleChart: boolean;
  visiblePChart = false;

  basicData = {};
  valuePerMonthChartData = {};
  weightPerMonthChartData = {};

  basicOptions = {
    plugins: {
      legend: {
        labels: {
          color: '#000000',
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: '#000000',
        },
        grid: {
          color: 'rgba(255,255,255,0.2)',
        },
      },
      y: {
        ticks: {
          color: '#000000',
        },
        grid: {
          color: 'rgba(255,255,255,0.2)',
        },
      },
    },
  };

  stackBarData = null;
  stackBaroptions = null;

  constructor(
    public transactionService: TransactionService,
    private store: Store<AuthStatus>,
    private commonService: CommonService,
    private downloadService: DownloadService,
    private datePipe: DatePipe,
    private titleService: TitleService
  ) {}

  ngOnInit() {
    this.setStackBarOptions();

    this.setDefualtFilters();
    this.titleService.setTitle('Transaktioner');
    this.store
      .select(
        (state) =>
          (this.selectedProfile = state['authStatus']['selectedProfile'])
      )
      .subscribe();
    this.fetchYards();
    this.fetchCostCenters();
    this.fetchArticles();
    this.fetchTableData();
  }

  setStackBarOptions() {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue(
      '--text-color-secondary'
    );
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
    this.stackBaroptions = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        legend: {
          labels: {
            color: textColor,
          },
        },
      },
      scales: {
        x: {
          stacked: true,
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false,
          },
        },
        y: {
          stacked: true,
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false,
          },
        },
      },
    };
  }

  clear() {
    this.toDate = null;
    this.fromDate = null;
    this.selectedArticles = new Array<Article>();
    this.selectedArticles = new Array<Article>();
    this.selectedYards = new Array<Yard>();
    this.selectedCostCenters = new Array<CostCenter>();
    this.TblFilters.purchaseNumber = null;
    this.setDefualtFilters();
    this.tblSortOrder = -1;
    this.fetchTableData();
  }

  setDefualtFilters() {
    this.TblFilters = {
      articleIds: null,
      purchaseNumber: '',
      pageSize: 10,
      page: 1,
      orderBy: this.sortingColPurchaseDate,
      sortOrder: 'DESC',
      smartCompanyId: 0,
      smartCustomerId: 0,
      costCenterIds: null,
      yardIds: null,
      startDate: null,
      endDate: null,
    };
  }

  fetchYards() {
    this.commonService
      .getYardsByCustomerIdAndCompanyId(
        this.selectedProfile.customerId,
        this.selectedProfile.companyId
      )
      .subscribe((data) => {
        this.yards = data;
      });
  }

  fetchCostCenters() {
    this.commonService
      .getCostCentersByCustomerIdAndCompanyId(
        this.selectedProfile.customerId,
        this.selectedProfile.companyId
      )
      .subscribe((data) => {
        this.costCenters = data;
      });
  }

  fetchArticles() {
    this.commonService
      .getArticleList(this.selectedProfile.customerId)
      .subscribe((data) => {
        for (var res of data) {
          res.displayText = res.articleNumber + ' (' + res.articleName + ')';
        }
        this.articles = data;
      });
  }

  get isIndividualCustomer(): boolean {
    if (this.selectedProfile.customerTypeId == CustomerType.Individual) {
      return true;
    }
    return false;
  }

  loadWeightPerMonthData() {
    this.setFilterData();
    this.transactionService
      .getTransactionWeightSummeryData(this.TblFilters)
      .subscribe((data) => {
        var dataArray = data.map((p) => p.totalWeight);
        var labelArray = data.map((p) => p.monthName);
        this.weightPerMonthChartData = {
          labels: labelArray,
          datasets: [
            {
              label: 'Levererad vikt',
              backgroundColor: '#FFA726',
              data: dataArray,
            },
          ],
        };

        this.visibleChart = true;
        setTimeout(() => {
          this.visiblePChart = true;
        }, 500);
      });
  }

  onPageChange(value) {
    this.TblFilters.page = value.page + 1;
    this.TblFilters.pageSize = value.rows;
    this.fetchTableData();
  }

  onFromDateChanged = () => {
    this.TblFilters.page = 1;
    this.TblFilters.startDate = this.fromDate;
    this.fetchTableData();
  };

  onToDateChanged = () => {
    this.TblFilters.page = 1;
    this.TblFilters.endDate = this.toDate;
    this.fetchTableData();
  };

  onYardSelectionChange = () => {
    this.TblFilters.page = 1;
    this.fetchTableData();
  };

  onCostCenterSelectionChange = () => {
    this.TblFilters.page = 1;
    this.fetchTableData();
  };

  onFilterPurchaseNumber() {
    this.TblFilters.page = 1;
    this.fetchTableData();
  }

  onArticleSelectionChange = () => {
    this.TblFilters.page = 1;
    this.fetchTableData();
  };

  customSort(value) {
    if (
      value.field != this.TblFilters.orderBy ||
      value.order != (this.TblFilters.sortOrder == 'ASC' ? 1 : -1)
    ) {
      this.TblFilters.orderBy = value.field;
      this.TblFilters.sortOrder = value.order == 1 ? 'ASC' : 'DESC';
      this.fetchTableData();
    }
  }

  fetchTableData() {
    this.setFilterData();
    this.transactionService.getTransactionData(this.TblFilters).subscribe({
      next: (data: PagedResponse<TransactionElement[]>) => {
        this.bindDataToGrid(data);
        data.pageNumber = data.pageNumber - 1;
        this.calculateTableTotal(data);
      },
    });
  }

  calculateTableTotal(data: PagedResponse<any[]>) {
    this.summaryTotals.weight = 0;
    this.summaryTotals.vatExclusiveAmount = 0;
    for (var res of data.results) {
      this.summaryTotals.weight = this.summaryTotals.weight + res.data.weight;
      this.summaryTotals.vatExclusiveAmount =
        this.summaryTotals.vatExclusiveAmount + res.data.vatExclusiveAmount;
    }
  }

  bindDataToGrid(data: PagedResponse<any[]>) {
    this.gridData.results = [];
    this.gridData = data;
    this.gridData.totalNumberOfRecords = data.totalNumberOfRecords;
  }

  export() {
    this.transactionService.exportTransactions(this.TblFilters).subscribe({
      next: (data: any) => {
        var base64 = this.downloadService.base64ToArrayBuffer(data);
        this.downloadService.saveByteArray(
          'Export Vinning kundportal - Transaktioner' +
            this.datePipe.transform(Date.now(), 'ddMMYYYY'),
          base64
        );
      },
    });
  }

  setFilterData() {
    this.TblFilters.smartCompanyId = Number(this.selectedProfile.companyId);
    this.TblFilters.smartCustomerId = Number(this.selectedProfile.customerId);

    if (this.selectedYards && this.selectedYards.length > 0) {
      this.TblFilters.yardIds = this.selectedYards
        .map((elem) => elem.id)
        .join(',');
    } else {
      this.TblFilters.yardIds = null;
    }

    if (this.selectedArticles && this.selectedArticles.length > 0) {
      this.TblFilters.articleIds = this.selectedArticles
        .map((elem) => elem.id)
        .join(',');
    } else {
      this.TblFilters.articleIds = null;
    }

    this.TblFilters.startDate =
      this.fromDate == null
        ? null
        : new Date(
            Date.UTC(
              this.fromDate.getFullYear(),
              this.fromDate.getMonth(),
              this.fromDate.getDate()
            )
          );
    this.TblFilters.endDate =
      this.toDate == null
        ? null
        : new Date(
            Date.UTC(
              this.toDate.getFullYear(),
              this.toDate.getMonth(),
              this.toDate.getDate()
            )
          );

    if (this.selectedCostCenters && this.selectedCostCenters.length > 0) {
      this.TblFilters.costCenterIds = this.selectedCostCenters
        .map((elem) => elem.id)
        .join(',');
    } else {
      this.TblFilters.costCenterIds = null;
    }
  }

  summaryDialogClosed() {
    this.visiblePChart = false;
  }

  getChartData() {
    this.setFilterData();
    this.getTransactionPerMothChartData();
    this.getValuePerMonthChartData();
    this.loadWeightPerMonthData();
  }

  getTransactionPerMothChartData() {
    this.transactionService
      .getTransactionSummeryData(this.TblFilters)
      .subscribe((data: any) => {
        var dataArray = data.map((p) => p.transactionCount);
        var labelArray = data.map((p) => p.monthName);
        this.basicData = {
          labels: labelArray,
          datasets: [
            {
              label: 'Antal transaktioner',
              backgroundColor: '#42A5F5',
              data: dataArray,
            },
          ],
        };
        this.visibleChart = true;
        setTimeout(() => {
          this.visiblePChart = true;
        }, 500);
      });
  }

  getValuePerMonthChartData() {
    this.transactionService
      .getTransactionValueSummeryData(this.TblFilters)
      .subscribe((data: TransactionValueSummeryData[]) => {
        var dataArray = data.map((p) => p.sumVATExclusiveAmount);
        var labelArray = data.map((p) => p.monthName);
        this.valuePerMonthChartData = {
          labels: labelArray,
          datasets: [
            {
              label: 'Total Värde',
              backgroundColor: '#66BB6A',
              data: dataArray,
            },
          ],
        };
        this.visibleChart = true;
        setTimeout(() => {
          this.visiblePChart = true;
        }, 500);
      });
  }
}
