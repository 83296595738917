import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SignUpRoutingModule } from './sign-up-routing.module';
import { SignUpComponent } from './components/sign-up.component';
import { StepsModule } from 'primeng/steps';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { CardModule } from 'primeng/card';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { ChipModule } from 'primeng/chip';
import { ChipsModule } from 'primeng/chips';
import { CheckboxModule } from 'primeng/checkbox';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { DialogModule } from 'primeng/dialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { TableModule } from 'primeng/table';
import { ReactiveFormsModule } from '@angular/forms';
import { ConfirmDialogModule} from 'primeng/confirmdialog'


import { CustomerDetailsComponent } from './components/customer-details/customer-details.component';
import { CustomerMapComponent } from './components/customer-map/customer-map.component';
import { DeclineSignupRequestComponent } from './components/decline-signup-request/decline-signup-request.component';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { AcceptSignupRequestComponent } from './components/accept-signup-request/accept-signup-request.component';
import { SignUpRequestsComponent } from './components/sign-up-requests/sign-up-requests.component';
import { ImageModule } from 'primeng/image';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { BlockUIModule } from 'primeng/blockui';
import { LowerCaseDirective } from 'src/app/shared/helpers/lowercase-directive';

@NgModule({
  declarations: [
    SignUpComponent,
    CustomerDetailsComponent,
    CustomerMapComponent,
    DeclineSignupRequestComponent,
    AcceptSignupRequestComponent,
    SignUpRequestsComponent,
    LowerCaseDirective
  ],
  imports: [
    CommonModule,
    SignUpRoutingModule,
    ImageModule,
    StepsModule,
    ToastModule,
    ButtonModule,
    CardModule,
    InputTextModule,
    DropdownModule,
    InputMaskModule,
    ToastModule,
    FormsModule,
    ReactiveFormsModule,
    TableModule,
    MultiSelectModule,
    ChipModule,
    CheckboxModule,
    MessageModule,
    MessagesModule,
    DialogModule,
    InputTextareaModule,
    ChipsModule,
    ConfirmDialogModule,
    OverlayPanelModule,
    BlockUIModule 
  ],
  providers: [MessageService, ConfirmationService],
})
export class SignUpModule {}
