export class ChartDataModel {
  labels: string[] = new Array<string>();
  datasets:ChartDataSetModel[] = new Array<ChartDataSetModel>();
  constructor(){
    this.datasets.push(new ChartDataSetModel());
  }
}
export class ChartDataSetModel {
  data: string[] = new Array<string>();
  backgroundColor: string[] = new Array<string>();
  hoverBackgroundColor: string[] = new Array<string>();
}

