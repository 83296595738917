import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ChartConfig } from '../models/chart-config.model';
import { Subject } from 'rxjs';

const AUTH_API = `${environment.apiUrl}/Authenticate`;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class ChartConfigService {
  config: ChartConfig = {
    theme: 'lara-light-blue',
    dark: false,
    inputStyle: 'outlined',
    ripple: true
};

private configUpdate = new Subject<ChartConfig>();

configUpdate$ = this.configUpdate.asObservable();

updateConfig(config: ChartConfig) {
    this.config = config;
    this.configUpdate.next(config);
}

getConfig() {
    return this.config;
}
}
