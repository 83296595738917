<div class="container-fluid d-flex align-items-stretch login-bg">
  <div class="login-container d-flex align-items-center w-100 justify-content-center">
    <div class="row w-50 bg-white shadow-sm">
        <div class="col-12 col-sx-12 col-sm-12 col-md-6 login-wrapper loginbg-img d-flex align-items-end text-end p-5">
           <div> <img src="assets/imgs/vinning_white.png" alt="" class="img-fluid fadeIn">
            <p class="text-white">Vi rustar en traditionell bransch inom järn- och metallåtervinning för morgondagens behov</p></div>
        </div>
        <div class="col-12 col-sx-12 col-sm-12 col-md-6 login-wrapper p-5 d-flex align-items-center" >
          <form [formGroup]="loginForm" class="form-horizontal w-100">
            <div class=" w-100"> <h2 class="mb-4 fadeIn">Logga in här!</h2>
              <div class="form-group mb-3">
                <label for="Username" class="fw-bold">Användarnamn (email)</label>
                <input type="text" class="w-100" pInputText formControlName="userName" [ngClass]="{'ng-invalid': !loginForm.controls['userName'].valid}">
                <!-- <small *ngIf="invalidUsernameInput()" id="confirmPasswordHelp" class="error-message">Please enter a valid email address!</small> -->
              </div>
              <div class="form-group">
                <label for="exampleInputPassword1" class="fw-bold">Lösenord</label>
                <p-password formControlName="password" (keyup.enter)="onSubmit()" styleClass="w-100" [toggleMask]="true" [feedback]="false" [ngClass]="{'ng-invalid': !loginForm.controls['password'].valid}">
                </p-password>
                <small
                *ngIf="loginForm.controls['password'].dirty && !loginForm.controls['password'].valid"
                id="password" class="p-error block">Felaktigt användarnamn eller lösenord!</small>
              </div>
              <div class="form-check text-end mb-3 mt-3">
                <p class="form-check-label small vinning-primary text-decoration-none" for="exampleCheck1"><span class="pointer" (click)="navigateToView(appRouterPath.resetPasswordRequest)">Glömt lösenord?</span></p>
              </div>
              <button pButton [disabled]="!loginForm.valid" (click)="onSubmit()" type="button" class="btn btn-dark w-100 p-2 fw-bold">Logga in</button>
              <div>
                  <p class="small mt-3">
                    Saknar du konto? Klicka <span (click)="navigateToView(appRouterPath.signup)" class="vinning-primary text-decoration-none pointer"><u> här</u></span> för att skapa ditt konto 
                  </p>
              </div>
          </div>
          </form>
      </div>
    </div>
  </div>
</div>
