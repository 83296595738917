import { AppRouterPath } from '../../../../shared/constants';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../../shared/services/auth.service';
import { DisplayMessages } from '../../../../shared/models/state.model';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { displayResetPasswordMessage } from 'src/app/store/actions/app.messages.action';
import { TitleService } from 'src/app/shared/services/title.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
export const passwordValidator = Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,}$/);

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
  providers: [MessageService]
})
export class ResetPasswordComponent implements OnInit {

  _passwordsMatch: boolean = true;
  email:string = "";
  token: string;
  change : string;

  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private messageService: MessageService,
    private titleService:TitleService,
    private store: Store<DisplayMessages>) {}

  ngOnInit(): void {
      this.activatedRoute.queryParams.subscribe(params => {
        this.token = params['token'];
        this.email = params['email'];
        this.change= params['change'];
        if(params['change'] == true){
          this.titleService.setTitle("Återställ lösenord");
        }else{
          this.titleService.setTitle("Skapa lösenord");
        }

      });
  }

  form: FormGroup = this.fb.group({
    newPassword: [null, [Validators.required as any,passwordValidator]],
    confirmPassword: [null, [Validators.required as any,passwordValidator]],
  });


  passwordsMatch(): void {
    this._passwordsMatch = this.form.get('newPassword').value == this.form.get('confirmPassword').value;
  }

  invalidInputs = (): boolean => {
    return !(this.form.get('newPassword').value  && this._passwordsMatch && this.token && this.email);
  }

  onSubmit = (_event: any) => {
    if(this.form.valid){
      this.authService.resetPassword(this.email, this.token, this.form.get('newPassword').value).subscribe({
        next: (data: any) => {
          if(data.status && data.status === 'Success') {
            this.messageService.add({ severity:'success', summary:'Framgång', detail:'Din registrering är klar.'});
            this.store.dispatch(displayResetPasswordMessage(true));
            this.router.navigateByUrl(`/${AppRouterPath.login}`);
          } else {
            this.displayResetError();
          }
        }, error: (_err) => {
          this.displayResetError();
        }
      });
    }
  }

  displayResetError = () => {
    this.messageService.add({ severity:'error', summary:'Fel', detail:'Något är fel på vår sida. Vänligen försök igen senare'});
    setTimeout(() => {
      this.messageService.clear();
    }, 10000);
  }
}
