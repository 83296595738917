<p-toast position="top-right"></p-toast>
<div class="container-fluid d-flex align-items-stretch login-bg">
    <div class="login-container d-flex align-items-center w-100 justify-content-center">
        <div class="row w-50 bg-white shadow-sm">
            <div class="col-12 col-sx-12 col-sm-12 col-md-6 login-wrapper loginbg-img d-flex align-items-end text-end p-5">
               <div> <img src="assets/imgs/vinning_white.png" alt="" class="img-fluid fadeIn">
                <p class="text-white">Vi rustar en traditionell bransch inom järn- och metallåtervinning för morgondagens behov</p></div>
            </div>
            <div class="col-12 col-sx-12 col-sm-12 col-md-6 login-wrapper p-5 d-flex align-items-center" >
              <form [formGroup]="form" class="form-horizontal">
                <div class=" w-100"> 
                  <h2 class="mb-4 fadeIn" *ngIf="!change">Ange nytt lösenord</h2>
                  <h2 class="mb-4 fadeIn" *ngIf="change">Skapa ditt lösenord</h2>
                  <div class="form-group mb-3">
                    <label for="newPassword" class="fw-bold" *ngIf="!change">Nytt lösenord</label>
                    <label for="newPassword" class="fw-bold" *ngIf="change">Lösenord</label>
                    <p-password formControlName="newPassword" (keyup)="passwordsMatch()" id="newPassword" aria-describedby="newPassword" [toggleMask]="true" [ngClass]="{'ng-invalid': !_passwordsMatch || !form.get('newPassword').valid}" [weakLabel]="'Stark'" [strongLabel]="'Svag'"></p-password>
                  </div>
                  <div class="form-group mb-3 pb-3">
                    <label for="confirmPassword" class="fw-bold">Bekräfta lösenord</label>
                    <p-password formControlName="confirmPassword" (keyup)="passwordsMatch()" id="confirmPassword" [ngClass]="{'ng-invalid': !_passwordsMatch || !form.get('confirmPassword').valid}" aria-describedby="confirmPassword" [weakLabel]="'Stark'" [strongLabel]="'Svag'" [toggleMask]="true">
                    </p-password>
                    
                    <small *ngIf="_passwordsMatch && form.get('newPassword').hasError('pattern')" class="error-message">Lösenordet ska innehålla ett stort tecken, ett gement tecken, en siffra och ett icke-alfanumeriskt tecken.</small>
                    <small *ngIf="!_passwordsMatch" id="confirmPasswordHelp" class="error-message">Lösenorden överensstämmer inte!</small>
                  </div>
                  <button pButton [disabled]="invalidInputs()" (click)="onSubmit($event)" type="button" class="btn btn-dark w-100 p-2 fw-bold ">Skicka in</button>
              </div>
              </form>
           
          </div>
        </div>
    </div>
     </div>
