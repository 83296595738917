import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[LowerCase]'
})
export class LowerCaseDirective {

  constructor() { }

  @HostListener('input', ['$event'])
  onInputChange(event: any) {
    let value = event.target.value;
    value = value.toLowerCase();
    event.target.value = value;
  }

}