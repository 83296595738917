import { createReducer, on } from "@ngrx/store";
import { DisplayMessages } from "src/app/shared/models/state.model";
import { displayResetPasswordMessage } from "../actions/app.messages.action";


export const initialDisplayMessagesState = {
  resetPassword: false
} as DisplayMessages;

export const displayMessagesReducer = createReducer(
  initialDisplayMessagesState,

  on(displayResetPasswordMessage, (_state, {resetPassword}) => ({resetPassword: resetPassword}))
)
