import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Company } from '../../models/company';
import { CustomerMappingData } from '../../models/customer-mapping-data';
import { CustomerType } from '../../models/customer-type';
import { SignUpData } from '../../models/sign-up.data';
import { SignUpService } from '../../services/sign-up.service';
import { ConfirmationService, Message, MessageService } from 'primeng/api';
import { AuthService } from 'src/app/shared/services/auth.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DownloadService } from 'src/app/shared/services/download-service';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'app-customer-map',
  templateUrl: './customer-map.component.html',
  styleUrls: ['./customer-map.component.css'],
})
export class CustomerMapComponent implements OnInit {
  onClearSSn() {
    this.loadCompany();
  }
  sub!: Subscription;
  signupData!: SignUpData;
  customerTypes!: CustomerType[];
  companies: Company[];

  customerType!: CustomerType;
  ssnOrg!: string;
  selectedCompanies: Company[] | undefined;

  selectedMapping!: CustomerMappingData;

  isAgreeToTerms!: boolean;
  submittedAdd!: boolean;
  ssnExist!: boolean;

  msgs!: Message[];

  displayModal: boolean;
  customerMapping: any;
  visibleSuccessfullModal = false;
  submitted=false;
  isLoadCompany=false;
  visibleHelpModal = false;
  @ViewChild('panel',{static:true}) panel: OverlayPanel;
  @ViewChild('elm',{static:true}) elm: ElementRef;
  blockedDocument: boolean = false;
  tempOldSSN="";

  constructor(
    private router: Router,
    private signupService: SignUpService,
    private authService: AuthService,
    private messageService: MessageService,
    private downloadService: DownloadService,
    private confirmationService: ConfirmationService,
    private cd: ChangeDetectorRef
  ) {
    this.signupData = this.router.getCurrentNavigation()?.extras
      .state as SignUpData;

    if (this.signupData) {
      localStorage.setItem('map-data', JSON.stringify(this.signupData));
    } else {
      this.signupData = JSON.parse(localStorage.getItem('map-data'));
    }

    this.signupData.customerMappings = [];
    this.ssnOrg = this.signupData.ssnOrg;
  }

  ngOnChangeMultiSelectDowndown() {
    this.cd.detectChanges();
  }

  ngOnInit() {
   this.focusInstructionPopup();
    
    this.customerMapping = new FormGroup({
      customerTypeFormFields: new FormControl(this.customerType, [
        Validators.required,
      ]),
      ssnOrgFormFields: new FormControl(this.ssnOrg, [Validators.required]),
      companiesFormField: new FormControl(this.selectedCompanies, [
        Validators.required,
      ]),
    });

    this.customerMapping
      .get('customerTypeFormFields')
      .valueChanges.subscribe((val) => {
        this.customerType = val as CustomerType;
      this.loadCompany();
    });

    this.ssnOrgModel.valueChanges.subscribe((val) => {
      this.onchangeSSN();
    });

    this.signupService.getCustomerTypes().subscribe((result) => {
      this.customerTypes = this.signupService.translateCustomerTypes(result);
      this.customerTypeModel.setValue(this.signupData.customerType);
    });
  }

 testmsg='test';

  getOnlyNumber(value):string{
    if(value){
      var fortmattedNo = value.match(/\d/g);
      if(fortmattedNo){
        return fortmattedNo.join("");
      }
     }
     return "";
  }

  focusInstructionPopup(){
    if(!sessionStorage.getItem("IsDisplaySignupInstructionPopup")){
      this.panel.toggle(null,this.elm.nativeElement);
      this.blockedDocument = true;
      setTimeout(() => {
          this.blockedDocument = false;
      }, 3000);
    }
    sessionStorage.setItem("IsDisplaySignupInstructionPopup","1");
  }

  get customerTypeModel() {
    return this.customerMapping.get('customerTypeFormFields');
  }

  get selectedCompaniesModel() {
    return this.customerMapping.get('companiesFormField');
  }

  get ssnOrgModel() {
    return this.customerMapping.get('ssnOrgFormFields');
  }

  closeInstructionPopup(){
    this.visibleHelpModal = false;
  }

  addRecord() {
    if (this.customerMapping.valid) {
      var ssnOrg = this.ssnOrgModel.value as string;
      var row = this.signupData.customerMappings.filter(
        (x) => x.ssnOrg == ssnOrg
      )[0];

      if (row) {
        this.ssnExist = true;
        this.ssnOrgModel.setErrors({ incorrect: true });
        return;
      }

      let data = new CustomerMappingData();
      data.companies = this.companies;
      data.customerType = this.customerTypeModel.value as CustomerType;
      data.ssnOrg = this.ssnOrgModel.value as string;
      data.selectedCompanies = this.selectedCompaniesModel.value as Company[];
      data.isDefaultSelection = data.ssnOrg == this.signupData.ssnOrg;

      this.signupData.customerMappings.push(data);
      this.ssnOrgModel.reset();
      this.selectedCompaniesModel.reset();
      this.ssnOrgModel.errors = [];
      this.companies = new Array<Company>();
      return;
    }
  }

  onChangeCustomerType(){
    this.customerMapping
    .get('ssnOrgFormFields').reset();
    this.customerMapping
    .get('companiesFormField').reset();
  }

  onchangeSSN(){
    if(this.getOnlyNumber(this.tempOldSSN) != this.getOnlyNumber(this.ssnOrgModel.value)){
      if(this.getOnlyNumber(this.ssnOrgModel.value).length < 10){
        this.isLoadCompany=false;
      }
     
      this.companies = new Array<Company>();
      this.selectedCompaniesModel.reset();
      var value = this.getOnlyNumber(this.ssnOrgModel.value);
      if (this.customerType.id == 2 && value.length >=10 || ( this.customerType.id == 1 && value.length >=10)) {
        this.ssnOrg = '';
        this.loadCompany();
      }
    }

    this.tempOldSSN = this.getOnlyNumber(this.ssnOrgModel.value);
  }

  onClickHelpBtn(){
    this.visibleHelpModal=true;
  }

  loadCompany() {
    this.companies = new Array<Company>();
    this.selectedCompaniesModel.reset();
    this.ssnExist = false;
    var customerType = this.customerType.id;
    var ssnOrg = this.ssnOrgModel.value.replace('_', '') as string;
    this.ssnOrg = ssnOrg;
    this.signupService
      .getCompanies(customerType, ssnOrg)
      .subscribe((result) => {
        result.forEach((element) => {
          element.ssnOrg = ssnOrg;
        });
        this.companies = result;
        this.selectedCompaniesModel.setValue(result);
        this.isLoadCompany=true;
        if (
          ssnOrg != '' &&
          (this.companies == null || this.companies.length == 0)
        ) {
          this.selectedCompaniesModel.setErrors({ incorrect: true });
        }
      });
  }

  deleteRow(mapingData: CustomerMappingData) {
    const index = this.signupData.customerMappings.indexOf(mapingData, 0);
    if (index > -1) {
      this.signupData.customerMappings.splice(index, 1);
    }
  }

  isValidMappedData():boolean{
    var isValid = true;
    for(var res of this.signupData.customerMappings){
      res.isValidCompanies = res.selectedCompanies.length < 1 ? false:true;
      isValid = res.isValidCompanies;
    }

    return isValid;
  }

  submitData() {
    if(this.isValidMappedData()){
      if (this.signupData.customerMappings?.length > 0) {
        this.authService.signupCustomer(this.signupData).subscribe((result) => {
          if (result.status == 'Success') {
            this.visibleSuccessfullModal = true;
          }
        },
        error => {      
          if(error.status == 400){
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.message });
          }
         });
      } else {
        this.submittedAdd = true;
      }
    }
  }

  backToLogin(){
    this.router.navigate(['/']);
  }

  onRemove(item: Company) {
    var row = this.signupData.customerMappings.filter(
      (x) => x.ssnOrg == item.ssnOrg
    )[0];
    if (row && row.selectedCompanies.length == 0) {
        this.deleteRow(row);
      }
  }

  downloadPolicy() {
    this.downloadService.downloadPDF(
      environment.gdprUrl,
      'Vinning GDPR Documentation'
    );
  }

  previousPage() {
    if (this.signupData.customerMappings.length > 0) {
    this.confirmationService.confirm({
        message:'Kundkopplingen sparas ej, vill du fortsätta?',
        header: 'Kundkoppling förloras',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.router.navigate(['sign-up/customer-details'], {
          state: this.signupData,
        });
      },
        reject: () => {},
      });
    } else {
      this.router.navigate(['sign-up/customer-details'], {
        state: this.signupData,
      });
      }
  }
}
