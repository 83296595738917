import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['../layout/layout.component.scss']
})
export class FooterComponent implements OnInit   {
 
  vinningWebSideURL:string = environment.vinningWebSideURL;
  containerServiceURL:string = environment.containerServiceURL;
  
  ngOnInit() {
   
  }

  goToLink(type: number){
    if(type == 1) window.open(this.vinningWebSideURL, "_blank");
    if(type == 2) window.open(this.containerServiceURL, "_blank");
}

}
