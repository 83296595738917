import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthStatus } from '../../../../shared/models/state.model';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  appHeader = false;

  constructor(private store: Store<AuthStatus>) {}

  ngOnInit() {
    this.store.select(state => state['authStatus']['loginFlowCompleted']).subscribe(value => {
      this.appHeader = value;
    });

  }
}
