import { FilteredSummaryRequest } from '../../../shared/models/common.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { PagedResponse } from '../../../shared/models/response.model';
import { SummaryElement } from '../../../shared/models/common.model';

const SUMMARY_API = `${environment.apiUrl}/summary`;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable({
  providedIn: 'root'
})
export class SummaryService {
  public summaryChartSubject = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) { }

  getPagedSummaryResults = (filters: FilteredSummaryRequest): Observable<PagedResponse<SummaryElement[]>> => {
    return this.http.post(
      `${SUMMARY_API}/get-summary-grid/paged`,
      filters,
      httpOptions
    ) as Observable<PagedResponse<SummaryElement[]>>;
  }

  export = (filters: FilteredSummaryRequest): Observable<any> => {
    return this.http.post(
      `${SUMMARY_API}/export-excel-summary`,
      filters,
      httpOptions
    ) as Observable<any>;
  }

  exportChart = (filters: FilteredSummaryRequest): Observable<any> => {
    return this.http.post(
      `${SUMMARY_API}/export-excel-chart-summary`,
      filters,
      httpOptions
    ) as Observable<any>;
  }

   getChart = (filters: FilteredSummaryRequest): Observable<any> => {
    return this.http.post(
      `${SUMMARY_API}/get-summary-chart`,
      filters,
      httpOptions
    ) as Observable<any>;
  }

  
  public openSummaryChart (value:string): void {
    this.summaryChartSubject.next(value);
  }

  get emitSummaryChartSubject(): BehaviorSubject<any> {
    return (this.summaryChartSubject as BehaviorSubject<any>);
  }
}
