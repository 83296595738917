<footer class="footer mt-auto  bg-dark">
    <div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class=" small shadow-sm p-3 text-white text-center">
                För frågor gällande avräkningar eller fakturor vänligen kontakta anläggningen. Telefonnummer hittar du<br>
                på avräkningen eller på våran hemsida
                <a class="url-style" (click)="goToLink(1)">www.vinning.se</a>
            </div>
        </div>
    </div>
</div>
</footer>