import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { FilteredSummaryRequest } from "src/app/shared/models/common.model";
import { environment } from "src/environments/environment";
import { FilteredTransactionTableRequest, TransactionElement, TransactionSummeryData, TransactionValueSummeryData, TransactionWeightSummeryData } from "../models/transaction-summery-data";
import { PagedResponse } from "src/app/shared/models/response.model";

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

@Injectable({
    providedIn: 'root'
  })
  export class TransactionService {
    
    exportTransactions =(filters: FilteredTransactionTableRequest) : Observable<any> => {
      return this.http.post(
        `${this.rootURL}/Transaction/export-excel-transactions`,
        filters,
        httpOptions
      ) as Observable<any>;
    }

    getTransactionData(filters: FilteredTransactionTableRequest) {
      return this.http.post(
      `${this.rootURL}/Transaction/get-transaction-grid/paged`,
      filters,
      httpOptions
    ) as Observable<PagedResponse<TransactionElement[]>>;
    }
   
    exportChart = (filters: FilteredTransactionTableRequest): Observable<any> => {
      return this.http.post(
        `${this.rootURL}/Transaction/export-excel-summary-chart`,
        filters,
        httpOptions
      ) as Observable<any>;
    }
  
    constructor(private http:HttpClient) { }
  
    rootURL = environment.apiUrl;
  
    getTransactionSummeryData(filters: FilteredTransactionTableRequest):Observable<TransactionSummeryData[]>  {
      return this.http.post<TransactionSummeryData[]>(this.rootURL + '/Transaction/get-summary-chart',
        filters,
        httpOptions
      );
    }

    getTransactionValueSummeryData(filters: FilteredTransactionTableRequest):Observable<TransactionValueSummeryData[]>  {
      return this.http.post<TransactionValueSummeryData[]>(this.rootURL + '/Transaction/get-summary-chart-value',
        filters,
        httpOptions
      );
    }

    getTransactionWeightSummeryData(filters: FilteredTransactionTableRequest):Observable<TransactionWeightSummeryData[]>  {
      return this.http.post<TransactionWeightSummeryData[]>(this.rootURL + '/Transaction/get-summary-chart-weight',
        filters,
        httpOptions
      );
    }

}