<div class="container-fluidp b-1 px-5 mt-5 pt-4 ">
  <div class="row mt-3">
    <div class="col-sm-12">
      <h5 class="fw-bold">Instrumentbräda</h5>
    </div>
  </div>
  <div class="row bg-white p-3 shadow-sm my-3 justify-content-start ">
        <div class="col-2">
            <div>
              <label Id="icon" for="icon" class="form-label">Anläggning</label>
              <div>
                <p-multiSelect
                  [options]="yards"    
                  [(ngModel)]="selectedYards"           
                  (ngModelChange)="onYardSelectionChange()"
                  optionLabel="yardName"
                  placeholder="Välj anläggning(ar)"
                  class="w-100"
                  emptyFilterMessage = "Hittas ej"
                ></p-multiSelect>
              </div>
            </div>
          </div>
          <div class="col-2" *ngIf="!isIndividualCustomer">
            <div>
              <label Id="icon" for="icon" class="form-label">Kostnadsställe</label>
              <div>
                <p-multiSelect
                  [options]="costCenters"      
                  [(ngModel)]="selectedCostCenters"      
                  (ngModelChange)="onCostCenterSelectionChange()"
                  optionLabel="costCenterName"
                  placeholder="Välj kostnadsställe(n)"
                  emptyFilterMessage = "Hittas ej"
                ></p-multiSelect>
              </div>
            </div>
          </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 d-flex align-items-start ps-0 d-flex align-items-stretch">
          <div class="bg-white p-3 shadow-sm w-100 mb-3 chart-wrapper">
              <div class="d-flex justify-content-between mb-3">
                  <h5>Transaktioner per månad</h5>
                  <div> 
                     <a (click)="exportTransaction()" class=" shadow-sm p-2 me-3 small">
                    <i class="pi pi-file-excel btn-operations color-primary"></i>
                  </a>
                    <a (click)="gotoPage('/transaction')" alt="My Image"><img
                              src="/assets/imgs/more.png" alt=""></a>
                  </div>
              </div>
              <p-chart type="bar" *ngIf="visibleChart" [data]="basicData" [options]="basicOptions"></p-chart>
          </div>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 d-flex align-items-start pe-0 d-flex align-items-stretch">
        <div class="bg-white p-3 shadow-sm w-100 mb-3 chart-wrapper">
            <div class="d-flex justify-content-between mb-3">
                <h5>Ersättning / kostnad per månad</h5>
                <div> 
                   <a (click)="exportTransaction()" class=" shadow-sm p-2 me-3 small">
                  <i class="pi pi-file-excel btn-operations color-primary"></i>
                </a>
                  <a (click)="gotoPage('/transaction')" alt="My Image"><img
                            src="/assets/imgs/more.png" alt=""></a>
                </div>
            </div>
            <p-chart type="bar" *ngIf="visibleChart" [data]="valuePerMonthData" [options]="basicOptions"></p-chart>
        </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-sm-12 col-md-6 col-lg-6 d-flex align-items-start ps-0 d-flex align-items-stretch">
      <div class="bg-white p-3 shadow-sm w-100 mb-3 chart-wrapper">
          <div class="d-flex justify-content-between mb-3">
              <h5>Vikt per månad</h5>
              <div> 
                 <a (click)="exportTransaction()" class=" shadow-sm p-2 me-3 small">
                <i class="pi pi-file-excel btn-operations color-primary"></i>
              </a>
                <a (click)="gotoPage('/transaction')" alt="My Image"><img
                          src="/assets/imgs/more.png" alt=""></a>
              </div>
          </div>
          <p-chart type="bar" *ngIf="visibleChart" [data]="weightChartData" [options]="basicOptions"></p-chart>
      </div>
  </div>
    <div class="col-12 col-sm-12 col-md-6 col-lg-6 d-flex align-items-start pe-0 d-flex align-items-stretch">
        <div class="bg-white p-3 shadow-sm w-100 mb-3 chart-wrapper">
       
            <div class="d-flex justify-content-between mb-3">
                <h5>Vikt per materialgrupp</h5>
                <div>        <a (click)="export()" class="shadow-sm p-2 me-3 small">
                  <i class="pi pi-file-excel btn-operations color-primary"></i>
                </a>
                  <a (click)="gotoPage('/summary')" alt="My Image"><img
                            src="/assets/imgs/more.png" alt="" (click)="export"></a>
                </div>
            </div>
            <div class="d-flex justify-content-between mb-3">
            <p-chart type="pie" *ngIf="visibleChart" [data]="chartData" [options]="chartOptions"  width="auto" height="250px"></p-chart>
            
            <p-table [value]="chartDataSource" styleClass="p-datatable-gridlines p-datatable-sm">
              <ng-template pTemplate="header">
                  <tr>
                      <th>Undergrupp</th>
                      <th class="text-end">Vikt <small>(kg)</small></th>
                  </tr>
              </ng-template>
              <ng-template pTemplate="body" let-product>
                  <tr>
                      <td>{{ product.articleSubGroupName }}</td>
                      <td class="text-end">{{ product.sum_Weight | number: '1.0-0':'lang' }}</td>
                  </tr>
              </ng-template>
              <ng-template pTemplate="footer">    
               <tr>
                <td>Summa</td>                
                <td class="text-end">{{getChartTotal() | number: '1.0-0':'lang'}}</td>
            </tr> </ng-template>
          </p-table>
          </div>
        </div>
    </div>
</div>
</div>


