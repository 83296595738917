<div class="container-fluid pb-1 px-5 mt-5 pt-4">
    <div class="row mt-3">
        <div class="col-sm-12">
            <h5 class="fw-bold">Transaktioner</h5>
        </div>
    </div>
    <div class="row bg-white p-3 shadow-sm my-3 justify-content-start">
        <div class="col-2">
            <div>
                <label Id="icon" for="icon" class="form-label">Från</label>
                <div>
                    <p-calendar [maxDate]="toDate" [(ngModel)]="fromDate" (ngModelChange)="onFromDateChanged()"
                        [showIcon]="true" inputId="icon" class="p-button-datetime" dateFormat="mm/dd/yy"
                        placeholder="mm/dd/yyyy"></p-calendar>
                </div>
            </div>
        </div>
        <div class="col-2">
            <div>
                <label Id="icon" for="icon" class="form-label">Till</label>
                <div>
                    <p-calendar [minDate]="fromDate" [(ngModel)]="toDate" (ngModelChange)="onToDateChanged()"
                        [showIcon]="true" inputId="icon" class="p-button-datetime" dateFormat="mm/dd/yy"
                        placeholder="mm/dd/yyyy" [disabled]="!fromDate"></p-calendar>
                </div>
            </div>
        </div>
        <div class="align-items-end col-2 d-flex mb-1">
            <button pButton label="Rensa" class="p-button-outlined" icon="pi pi-filter-slash"
                (click)="clear()"></button>
        </div>
        <div class="col-3 ms-auto d-flex align-items-end justify-content-end">
            <div>
                <a (click)="export()" class="btn btn-light shadow-sm p-2 me-3 small">
                    <i class="pi pi-file-excel btn-operations color-primary "></i><br>
                    <label class="form-label small mb-0">Exportera</label>
                </a>

                <a (click)="getChartData()" class="btn btn-light shadow-sm p-2 ">
                    <i class="pi pi-chart-pie btn-operations color-primary"></i><br><label
                        class="form-label small mb-0">Graf</label>
                </a>
            </div>
        </div>
    </div>
    <div class="row bg-white p-3 shadow-sm mb-5">
        <div class="col-12">
            <p-treeTable [value]="gridData.results"
                styleClass="mt-2 p-treetable-gridlines p-treetable-sm  p-datatable-sm" [rowHover]="true"
                [customSort]="true" expandableRowGroups="true" stateStorage="local" stateKey="statedemo-local"
                [showCurrentPageReport]="true"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                sortField="{{TblFilters.orderBy}}" [sortOrder]="tblSortOrder" (sortFunction)="customSort($event)">
                <ng-template pTemplate="header">
                    <tr>
                        <th [ttSortableColumn]="sortingColYardName">
                            Anläggning
                            <p-treeTableSortIcon [field]="sortingColYardName"></p-treeTableSortIcon>
                        </th>

                        <th *ngIf="!isIndividualCustomer" [ttSortableColumn]="sortingColCostCenter">
                            Kostnadsställe
                            <p-treeTableSortIcon [field]="sortingColCostCenter"></p-treeTableSortIcon>

                        </th>
                        <th [ttSortableColumn]="sortingColPurchaseDate">
                            Datum
                            <p-treeTableSortIcon [field]="sortingColPurchaseDate"></p-treeTableSortIcon>
                        </th>
                        <th [ttSortableColumn]="sortingColPurchaseNumber">
                            Inköpsnummer
                            <p-treeTableSortIcon [field]="sortingColPurchaseNumber"></p-treeTableSortIcon>
                        </th>
                        <th>
                            Artikel
                        </th>
                        <th class="text-end">
                            Vikt <small>(kg)</small>
                        </th>
                        <th class="text-end">
                            Belopp (ex. moms)
                        </th>
                        <th class="text-end">
                            Pris <small>(kr/kg)</small>
                        </th>
                    </tr>
                    <tr>
                        <th>
                            <p-multiSelect [options]="yards" [(ngModel)]="selectedYards"
                                (ngModelChange)="onYardSelectionChange()" optionLabel="yardName"
                                emptyFilterMessage="Hittas ej" placeholder="Välj anläggning(ar)"
                                class="w-100"></p-multiSelect>
                        </th>
                        <th *ngIf="!isIndividualCustomer">
                            <p-multiSelect [options]="costCenters" [(ngModel)]="selectedCostCenters"
                                (ngModelChange)="onCostCenterSelectionChange()" optionLabel="costCenterName"
                                emptyFilterMessage="Hittas ej" placeholder="Välj kostnadsställe(n)"></p-multiSelect>
                        </th>
                        <th></th>
                        <th>
                            <input type="text" pInputText placeholder="Ange inköpsnummer"
                                (keyup.enter)="onFilterPurchaseNumber()" [(ngModel)]="TblFilters.purchaseNumber">
                        </th>
                        <th>
                            <p-multiSelect [options]="articles" [(ngModel)]="selectedArticles"
                                (ngModelChange)="onArticleSelectionChange()" optionLabel="displayText"
                                emptyFilterMessage="Hittas ej" placeholder="Välj artikel">
                                <ng-template let-item pTemplate="optionLabel">
                                    {{item.displayText}}
                                </ng-template>
                            </p-multiSelect>
                        </th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
                    <tr [ttRow]="rowNode">
                        <td>
                            <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                            {{rowData.yardName}}
                        </td>
                        <td *ngIf="!isIndividualCustomer">{{rowData.costCenter}}</td>
                        <td>{{rowData.purchaseDate | date}}</td>
                        <td>{{rowData.purchaseNumber}}</td>
                        <td>{{rowData.articleNumber}} <span *ngIf="rowData.description">
                                ({{rowData.description}})</span></td>
                        <td class="text-end">{{rowData.weight | number: '1.0-0':'lang'}}</td>
                        <td class="text-end">{{rowData.vatExclusiveAmount | currency:'SEK':'symbol':'1.0-0':'lang'}}
                        </td>
                        <td class="text-end">{{rowData.price | currency:'SEK':'symbol':'1.3-3':'lang'}}</td>
                    </tr>
                </ng-template>
                <ng-template *ngIf="gridData.results" pTemplate="footer">
                    <tr>
                        <td *ngIf="isIndividualCustomer" colspan="4" class="text-left">Summa</td>
                        <td *ngIf="!isIndividualCustomer" colspan="5" class="text-left">Summa</td>
                        <td class="text-end">{{summaryTotals.weight| number: '1.0-0':'lang'}}</td>
                        <td class="text-end">{{summaryTotals.vatExclusiveAmount |
                            currency:'SEK':'symbol':'1.0-0':'lang'}}</td>
                        <td></td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="7" *ngIf="isIndividualCustomer" class="text-center">Ingen information hittad.</td>
                        <td colspan="8" *ngIf="!isIndividualCustomer" class="text-center">Ingen information hittad.</td>
                    </tr>
                </ng-template>
            </p-treeTable>
            <p-paginator [showCurrentPageReport]="true"
                currentPageReportTemplate="Visar {first} till {last} av {totalRecords} rader"
                (onPageChange)="onPageChange($event)" [first]="pageNumber" [rows]="10"
                [totalRecords]="gridData.totalNumberOfRecords" [rowsPerPageOptions]="[10, 20, 30]"></p-paginator>
        </div>
    </div>
</div>

<p-dialog (onHide)="summaryDialogClosed()" header="Transaktionssammanfattning" [(visible)]="visibleChart"
    [style]="{ 'width': '50%' }" [modal]="true">
    <p-tabView>
        <p-tabPanel header="Transaktioner">
            <div class="text-center" *ngIf="!visiblePChart">
                <p-progressSpinner></p-progressSpinner>
            </div>
            <p-chart type="bar" *ngIf="visiblePChart" [data]="basicData" [options]="basicOptions"></p-chart>
        </p-tabPanel>
        <p-tabPanel header="Värde">
            <div class="text-center" *ngIf="!visiblePChart">
                <p-progressSpinner></p-progressSpinner>
            </div>
            <p-chart type="bar" *ngIf="visiblePChart" [data]="valuePerMonthChartData" [options]="basicOptions"></p-chart>
        </p-tabPanel>
        <p-tabPanel header="Vikt">
            <div class="text-center" *ngIf="!visiblePChart">
                <p-progressSpinner></p-progressSpinner>
            </div>
            <p-chart type="bar" *ngIf="visiblePChart" [data]="weightPerMonthChartData" [options]="basicOptions"></p-chart>
        </p-tabPanel>
    </p-tabView>
</p-dialog>