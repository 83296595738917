export const AppRouterPath = {
  login: 'login',
  signup:'sign-up',
  transaction:'transaction',
  summary:'summary',
  wasteCode:"waste-code",
  dashboard:"dashboard",
  resetPassword:"reset-password",
  resetPasswordRequest:"reset-password-request",
  selectCustomer: "select-customer",
  validateEmail:"validate-email",
  customerDetails:"customer-details",
  customerMapping:"customer-mapping",
  signupDecline:"signup-decline/:userId/:email/:customerId",
  signupAccept:"signup-accept/:userId/:email/:customerId",
  signupRequests:"signup-requests"
}

export enum StorageKey {
  ACCESS_TOKEN = "access-token",
  REFRESH_TOKEN = "refresh-token",
  PROFILE = "profile"
}

export enum CustomerType {
  Organization = 1,
  Individual = 2
}


