import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppRouterPath } from '../../shared/constants';
import { TransactionComponent } from './component/transaction.component';
import { AuthGuard } from '../../shared/helpers/auth.guard';

const routes: Routes = [{ path: AppRouterPath.transaction, component: TransactionComponent}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TransactionRoutingModule { }
