import { FilteredSummaryRequest } from '../../../shared/models/common.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { PagedResponse } from '../../../shared/models/response.model';
import { SummaryElement } from '../../../shared/models/common.model';
import { FilteredWasteCodeRequest, FilteredWasteCodeTableRequest, WasteCodeElement } from '../models/waste-code-grid.model';

const SUMMARY_API = `${environment.apiUrl}/WasteCode`;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class WasteCodeService {

  constructor(private http: HttpClient) { }

  getEWCWasteCodePagedResults = (filters: FilteredWasteCodeTableRequest): Observable<PagedResponse<WasteCodeElement[]>> => {
    return this.http.post(
      `${SUMMARY_API}/get-wastecode-ewc-grid/paged`,
      filters,
      httpOptions
    ) as Observable<PagedResponse<WasteCodeElement[]>>;
  }

  getRDWasteCodePagedResults = (filters: FilteredWasteCodeTableRequest): Observable<PagedResponse<WasteCodeElement[]>> => {
    return this.http.post(
      `${SUMMARY_API}/get-wastecode-rd-grid/paged`,
      filters,
      httpOptions
    ) as Observable<PagedResponse<WasteCodeElement[]>>;
  }

  exportEWCWasteCodeView = (filters: FilteredWasteCodeRequest): Observable<any> => {
    return this.http.post(
      `${SUMMARY_API}/export-excel-wastecode-ewc`,
      filters,
      httpOptions
    ) as Observable<any>;
  }

  exportRDWasteCodeView = (filters: FilteredWasteCodeRequest): Observable<any> => {
    return this.http.post(
      `${SUMMARY_API}/export-excel-wastecode-rd`,
      filters,
      httpOptions
    ) as Observable<any>;
  }
}
