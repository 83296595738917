import { Component, OnInit } from '@angular/core';
import { AppRouterPath } from '../../../../shared/constants';
import { Router } from '@angular/router';
import { CustomerService } from '../../../../shared/services/customer.service';
import { Customer } from 'src/app/shared/models/customer.model';
import { CompanyService } from 'src/app/shared/services/company.service';
import { Company } from 'src/app/shared/models/company.model';
import { Store } from '@ngrx/store';
import { AuthStatus, SelectedProfile } from '../../../../shared/models/state.model';
import { reviseSelectedProfile, reviseLoginFlowStatus, persistProfile } from 'src/app/store/actions/app.auth.action';
import { TitleService } from 'src/app/shared/services/title.service';

@Component({
  selector: 'app-select-customer',
  templateUrl: './select-customer.component.html',
  styleUrls: ['./select-customer.component.css']
})
export class SelectCustomerComponent implements OnInit {

  selectedCustomer: Customer = {} as Customer;
  selectedCompany: Company = {} as Company;

  customers: Customer[];
  companies: Company[];
  selectedProfile:SelectedProfile;

  constructor(
    private companyService: CompanyService,
    private customerService: CustomerService,
    private router: Router,
    private titleService:TitleService,
    private store: Store<AuthStatus>) {}

  ngOnInit(): void {
    this.titleService.setTitle("Välj kund");
    this.fetchCustomers();
    this.loadSelectedCustomer();
  }

  loadSelectedCustomer(){
    this.store.select(state => state['authStatus']['selectedProfile']).subscribe((profile: SelectedProfile) => {
     this.selectedProfile=profile;
    })
  }

  fetchCustomers() {
    this.customerService.get().subscribe({
      next: (data: Customer[]) => {
        this.customers = data;
      
        if(this.customers) {
          this.customers.forEach(customer => {
            customer.displayText = customer.externalNumber? `${customer.externalNumber} - ${customer.name}`: customer.name;
          })
        }

        if(this.customers.length === 1) {
          this.selectedCustomer = this.customers[0];
          this.fetchCompanies();
          return;
        }
     
        if(this.selectedProfile && this.selectedProfile.customerId){
          var selectedCustomer = data.find(x=>x.id == this.selectedProfile.mainCustomerId);
          if(selectedCustomer){
            this.selectedCustomer = selectedCustomer;
            this.fetchCompanies();
          }
        }
      }
    });
  }

  fetchCompanies() {
    this.selectedCompany = null;
    this.companyService.getByCompanyId(this.selectedCustomer.userCustomerMappingId).subscribe({
      next: (data: Company[]) => {
        this.companies = data;
        if(this.companies.length === 1) {
          this.selectedCompany = this.companies[0];
          return;
        }

        if(this.selectedProfile && this.selectedProfile.companyId){
          var selectedCompany = data.find(x=>x.id == this.selectedProfile.companyId);
          if(selectedCompany){
            this.selectedCompany = selectedCompany;
          }
        }
      }
    });
  }

  onCustomerSelectionChange() {
    this.fetchCompanies();
  }

  onSubmit(_event): void {
    if(this.selectedCustomer && this.selectedCustomer.id && this.selectedCompany && this.selectedCompany.id){
      this.store.dispatch(reviseSelectedProfile({
        customerId: this.selectedCompany.customerId,
        companyId: this.selectedCompany.id,
        customerTypeId:this.selectedCustomer.purchasingCustomerTypeId,
        mainCustomerId: this.selectedCustomer.id
      }));
      
      this.store.dispatch(reviseLoginFlowStatus(true));
      this.store.dispatch(persistProfile());
      this.navigateToView(`/${AppRouterPath.dashboard}`);
    }
  }

  get invalidInputs(): boolean {
    return !(this.selectedCompany && this.selectedCompany.id && this.selectedCustomer && this.selectedCustomer.id)
  }

  navigateToView = (url: string) => {
    this.router.navigateByUrl(url);
  }
}
