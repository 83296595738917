import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppRouterPath } from '../../shared/constants';
import { LoginComponent } from './components/login/login.component';
import { SelectCustomerComponent } from './components/select-customer/select-customer.component';
import { LoginAuthGuard } from 'src/app/shared/helpers/login-auth.guard';

const routes: Routes = [
  { path: AppRouterPath.login, component: LoginComponent },
  { path: AppRouterPath.selectCustomer, component: SelectCustomerComponent, canActivate: [LoginAuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LoginRoutingModule { }
