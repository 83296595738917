import { StorageKey } from '../constants';

class LocalStorage {

  constructor() { }

  public static clean = (): void => {
    window.localStorage.clear();
  }

  public static save = (key: StorageKey, value: any) => {
    window.localStorage.removeItem(key);
    window.localStorage.setItem(key, JSON.stringify(value));
  }

  public static get = (key: StorageKey): any => {
    const value = window.localStorage.getItem(key);
    if (value) {
      return JSON.parse(value);
    }

    return false;
  }
}

export const appLocalStorage = LocalStorage;
