import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const CUSTOMER_API = `${environment.apiUrl}/customer`;

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private http: HttpClient) { }

  get = (): Observable<any> => {
    return this.http.get(
      `${CUSTOMER_API}/get-user-mapped-customers`
    );
  }

  getById = (id: string | number): Observable<any> => {
    return this.http.get(
      `${CUSTOMER_API}/get-customer/${id}`
    );
  }

  isSingleCusomerAndSingleCompany = (): Observable<any> => {
    return this.http.get(
      `${CUSTOMER_API}/check-onlyone-mapped-customer-company`
    );
  }
}
