import { Component } from '@angular/core';
import { AuthService } from '../../../../shared/services/auth.service';
import { TitleService } from 'src/app/shared/services/title.service';

@Component({
  selector: 'app-reset-password-request',
  templateUrl: './reset-password-request.component.html',
  styleUrls: ['./reset-password-request.component.css'],
})
export class ResetPasswordRequestComponent {

  identifier: string;
  requestSent: boolean = false;
  requestFailed: boolean = false;
  
  constructor(private authService: AuthService, private titleService:TitleService) {
    titleService.setTitle("Reset Password Request");
  }

  validEmailInput = (): boolean => {
    const regex = /^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/;
    return !!this.identifier || regex.test(this.identifier);
  }

  invalidInput = (): boolean => {
    return !this.identifier || !this.validEmailInput();
  }

  onSubmit = (_event: any) => {
    this.authService.requestResetPassword(this.identifier).subscribe({
      next: (data: any) => {
        this.requestSent = true;
      },
      error: (error: any) => {
        this.requestFailed=true;
      }
    });
  }
}
