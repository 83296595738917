import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ResetPasswordRoutingModule } from './reset-password-routing.module';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ResetPasswordRequestComponent } from './components/reset-password-request/reset-password-request.component';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PasswordModule } from 'primeng/password';
import { ToastModule } from 'primeng/toast';


@NgModule({
  declarations: [
    ResetPasswordComponent,
    ResetPasswordRequestComponent
  ],
  imports: [
    ReactiveFormsModule,
    CommonModule,
    ResetPasswordRoutingModule,
    InputTextModule,
    FormsModule,
    PasswordModule,
    ToastModule,
  ]
})
export class ResetPasswordModule { }
