import { Injectable } from '@angular/core';
import { SubjectService } from './subject.service.';

@Injectable({
  providedIn: 'root',
})
export class HTTPMonitoringService {
  pendingRequestsNumber: number = 0;
  constructor(private subjectService: SubjectService) {}

  start() {
    if(this.pendingRequestsNumber < 0) this.pendingRequestsNumber = 0;
    this.pendingRequestsNumber++;

    if (this.pendingRequestsNumber == 1) {
      this.subjectService.loading(true);
    }
  }

  stop() {
    this.pendingRequestsNumber--;
    if (this.pendingRequestsNumber == 0) {
      this.subjectService.loading(false);
    }
  }

  forceStop() {
    this.pendingRequestsNumber = 0;
    this.subjectService.loading(false);
  }
}
