<nav class="navbar navbar-expand-lg navbar-light bg-theme py-0 shadow-sm fixed-top">
  <div class="container-fluid py-0">
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <a class="navbar-brand my-0 py-0" (click)="goToDashboard()"><img src="../assets/imgs/vinning_logo_.png" class="img-fluid logo-vinning"
        id="header-navbarlogo-span" width="180"></a>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">

      <ul class="navbar-nav me-auto">
        <li class="nav-item ">
          <a class="nav-link  color-default menu-font px-3 py-4 " routerLink="/transaction"
            routerLinkActive="active">{{'Transaktioner'}}</a>
        </li>
        <li class="nav-item ">
          <a class="nav-link  color-default menu-font px-3 py-4 " routerLink="/summary"
            routerLinkActive="active">{{'Material'}}</a>
        </li>
        <li class="nav-item ">
          <a class="nav-link  color-default menu-font px-3 py-4 " routerLink="/waste-code"
            routerLinkActive="active">{{'Avfallskoder'}}</a>
        </li>
      </ul>
      <button type="button" pButton icon="pi pi-building"  class="p-button-transparent" label="{{companyName}}"></button>
      <button type="button" pButton icon="pi pi-user"  class="p-button-transparent" label="{{customerName}}"></button>
      <button type="button" pButton icon="pi pi-chevron-down" label="" (click)="menu.toggle($event)" class="p-button-transparent"></button>
      <p-menu #menu [popup]="true" [model]="items"></p-menu>
    </div>
  </div>
</nav>

