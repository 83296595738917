import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WasteCodeRoutingModule } from './waste-code-routing.module';
import { WasteCodeComponent } from './component/waste-code.component';
import { PaginatorModule } from 'primeng/paginator';
import {TableModule} from 'primeng/table';
import {ToastModule} from 'primeng/toast';
import {CalendarModule} from 'primeng/calendar';
import {SliderModule} from 'primeng/slider';
import {MultiSelectModule} from 'primeng/multiselect';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DialogModule} from 'primeng/dialog';
import {ButtonModule} from 'primeng/button';
import {DropdownModule} from 'primeng/dropdown';
import {InputTextModule} from 'primeng/inputtext';
import { WasteCodeGridComponent } from './component/waste-code-grid/waste-code-grid.component';
import {TreeTableModule} from 'primeng/treetable';

@NgModule({
  declarations: [
    WasteCodeComponent,
    WasteCodeGridComponent
  ],
  imports: [
    CommonModule,
    WasteCodeRoutingModule,
    CommonModule,
    WasteCodeRoutingModule,
    PaginatorModule,
    TableModule,
    TableModule,
    CalendarModule,
		SliderModule,
		DialogModule,
		MultiSelectModule,
		ContextMenuModule,
		DropdownModule,
		ButtonModule,
		ToastModule,
    InputTextModule,
    TreeTableModule
  ]
})
export class WasteCodeModule { }
