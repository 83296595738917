import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AuthService } from 'src/app/shared/services/auth.service';
import { AcceptSignupRequest } from '../../models/accept-signup-request';
import { TitleService } from 'src/app/shared/services/title.service';

@Component({
  selector: 'app-accept-signup-request',
  templateUrl: './accept-signup-request.component.html',
  styleUrls: ['./accept-signup-request.component.css']
})
export class AcceptSignupRequestComponent implements OnInit,OnDestroy {
  message !: string;
  sub: any;
  email !: string;
  userId !:string;
  displayModal !: boolean;
  responseMessage !: string;
  customerId: number;

  constructor(private route: ActivatedRoute, private authservice:AuthService, private messageService:MessageService,
    private titleService:TitleService,
    private router: Router, private confirmationService: ConfirmationService) {}

  ngOnInit() {
    this.titleService.setTitle("Godkänn registreringförfrågan");
    this.sub = this.route.params.subscribe(params => {
       this.email = params['email']; 
       this.userId = params['userId']; 
       this.customerId = +params['customerId']; 
    });
    this.message="";
  }

  submit(){ 
    this.confirmationService.confirm({
      message: 'Är du säker på att du vill acceptera denna begäran?',
      icon: 'pi pi-exclamation-triangle',
      header:'Acceptera bekräftelse',
      accept: () => {
        this.accept();
          //this.msgs = [{severity:'info', summary:'Confirmed', detail:'You have accepted'}];
      },
      reject: () => {
          //this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
      }
  });
  }
  accept() {
    let acceptRequest= new AcceptSignupRequest();
    acceptRequest.message= this.message;
    acceptRequest.userId = this.userId;
    acceptRequest.customerId = this.customerId;

    this.authservice.accceptSignupRequest(acceptRequest).subscribe(res=>{
      this.messageService.add({  severity: 'success', summary: 'Framgång', detail: res.message });
      setTimeout (()=>this.closeDialog(), 1500);
    },    
    error => {      
      this.messageService.add({  severity: 'error', summary: 'Error', detail: error.error.message });
     }
     );
  }

  closeDialog(){
    this.displayModal =false;
    window.self.close();      
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}

