import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { ArticleWasteCode } from 'src/app/shared/models/article-wastecode';
import { Article } from 'src/app/shared/models/article.model';
import { PagedResponse } from 'src/app/shared/models/response.model';
import { FilteredWasteCodeRequest, FilteredWasteCodeTableRequest, WasteCodeElement, WasteCodeElementsTotal } from '../../models/waste-code-grid.model';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-waste-code-grid',
  templateUrl: './waste-code-grid.component.html',
  styleUrls: ['./waste-code-grid.component.css']
})
export class WasteCodeGridComponent {
  @Input() gridData: PagedResponse<TreeNode[]> = {
    results: [],
    pageNumber: 0,
    pageSize: 0,
    total: 0,
    orderBy: 'WasteCode',
    sortOrder: '',
    totalNumberOfRecords: 0,
  };
  sortingColFieldName:string = 'WasteCode';
  @Input() isEWCWasteCodeGrid:boolean = false;
  @Input() summaryTotals: WasteCodeElementsTotal = {
    weight: 0
  };
  @Output() refreshTable: EventEmitter<any> = new EventEmitter();
  @Output() exportEvent: EventEmitter<any> = new EventEmitter();

  @Input() pageNumber = 0;
  selectedArticle:Article;
  articles:Article[];
  selectedArticleWasteCode:Article;
  articlesWasteCodes:ArticleWasteCode[];
  @Input() tblFilters: FilteredWasteCodeTableRequest;
  
  onPageChange(value){
    this.tblFilters.page = value.page + 1;
    this.tblFilters.pageSize = value.rows;
    this.fetchTableData();
  }

  customSort(value){
    if (
      value.field != this.tblFilters.orderBy ||
      value.order != (this.tblFilters.sortOrder == 'ASC' ? 1 : -1)
    ) {
      this.tblFilters.orderBy = value.field;
      this.tblFilters.sortOrder = value.order == 1 ? 'ASC' : 'DESC';
      this.fetchTableData();
    }
  }

  onChangedFilters(){
    this.tblFilters.page = 1;
    this.fetchTableData();
  }

  fetchTableData(){
    this.refreshTable.emit(this.tblFilters);
  }
  
  export(){
    this.exportEvent.emit(this.isEWCWasteCodeGrid);
  }

}
