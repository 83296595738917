import { CustomerMappingData } from "./customer-mapping-data";
import { CustomerType } from "./customer-type";

export class SignUpData {

  customerType !:CustomerType;
  email:string | undefined;
  ssnOrg !: string;
  phone:string | undefined;
  customerMappings !: CustomerMappingData[];
  
  /**
   *
   */
  constructor() {
    this.customerMappings = [];    
  }
}