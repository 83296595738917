import { Company } from "./company";
import { CustomerType } from "./customer-type";

export class CustomerMappingData {
    customerType: CustomerType | undefined;
    ssnOrg: string | undefined;
    companies: Company[] | undefined;
    selectedCompanies !: Company[];    
    isDefaultSelection !:boolean;
    isValidCompanies:boolean;
}