<form [formGroup]="customerMapping">
    <div class="row">
        <div class="col-12 col-lg-3">
            <div class="form-group ">
                <label for="type" class="fw-bold label-required">Kundtyp</label>
                <p-dropdown (onChange)="onChangeCustomerType()" formControlName="customerTypeFormFields"
                    styleClass="p-inputtext-sm" inputId="class" [options]="customerTypes" optionLabel="name"
                    placeholder="Välj kundtyp"></p-dropdown>
            </div>
        </div>
        <div class="col-12 col-lg-3">
            <div class="form-group">
                <div *ngIf="customerType?.id == 1">
                    <label for="ssn-org" class="fw-bold label-required">Organisationsnummer</label>
                    <p-inputMask class="p-inputtext-sm" id="ssn" aria-describedby="Ssn" placeholder="Ange organisationsnummer"
                        mask="999999-9999?9"  [required]="true"
                        formControlName="ssnOrgFormFields"></p-inputMask>

                    <small
                        *ngIf="((ssnOrgModel.dirty && ssnOrgModel.invalid)) && ssnOrgModel.errors['required']"
                        class="p-error">Organisationsnummer krävs.</small>
                    <small *ngIf="((ssnOrgModel.dirty && ssnOrgModel.invalid)) && ssnExist"
                        class="p-error">Organisationsnummer redan tillagd.</small>
                </div>
                <div *ngIf="customerType?.id == 2 || !customerType?.id">
                    <label for="ssn-org" class="fw-bold label-required">Personnummer</label>
                    <p-inputMask class="p-inputtext-sm" id="ssn" aria-describedby="Ssn" placeholder="Ange SSN"
                        mask="9999999999"  [required]="true"
                        formControlName="ssnOrgFormFields"></p-inputMask>
                    <small
                        *ngIf="((ssnOrgModel.dirty && ssnOrgModel.invalid)) && ssnOrgModel.errors['required']"
                        class="p-error">Personnummer krävs.</small> 
                    <small *ngIf="((ssnOrgModel.dirty && ssnOrgModel.invalid)) && ssnExist"
                        class="p-error">Personnummer redan tillagd.</small>
                </div>
            </div>
        </div>

        <div class="col-12 col-lg-3">
            <div class="form-group ">
                <label for="type" class="fw-bold label-required" >Anläggning</label>
                <p-multiSelect Styleclass="p-inputtext-sm" inputId="company" [options]="companies" emptyFilterMessage = "Hittas ej"
                    optionLabel="companyName" placeholder="Välj Anläggning" [required]="true" 
                    formControlName="companiesFormField">
                    <ng-template let-value pTemplate="selectedItems">
                        <div *ngIf="selectedCompaniesModel.value && selectedCompaniesModel.value.length == 1">{{ selectedCompaniesModel.value.length }} Anläggning vald</div>
                        <div *ngIf="selectedCompaniesModel.value && selectedCompaniesModel.value.length > 1">{{ selectedCompaniesModel.value.length }} Anläggningar valda</div>
                        <div *ngIf="!selectedCompaniesModel.value || selectedCompaniesModel.value.length === 0">Välj Anläggning</div>
                    </ng-template>
                    <ng-template let-companies pTemplate="item">
                        <div class="flex align-items-center gap-2">
                            <div>{{ companies.companyName }}</div>
                        </div>
                    </ng-template>
                </p-multiSelect>
                    <div *ngIf="selectedCompaniesModel.invalid || (selectedCompaniesModel.dirty && selectedCompaniesModel.invalid)">
                        <small *ngIf="!(companies == null || companies.length ==0) && ssnOrgModel.value && selectedCompaniesModel.errors['required']"
                        class="p-error w-100">Välj ett eller flera organisations.</small>
                    <small *ngIf="( isLoadCompany && ssnOrg != '' && (companies == null || companies.length ==0)) && ssnOrgModel.value" class="p-error w-100">Ingen kund hittas för angivet organisations- eller personnummer.</small>
                    </div>
            </div>
        </div>

        <div class="col-12 col-lg-3 text-end">
            <p-button label="Lägg till" [disabled]="customerMapping.invalid" (onClick)="addRecord()"
                styleClass=" mt-3 w-75 p-button-primary"></p-button>
        </div>
    </div>
</form>

<div class="col-12" #pnl>
    <p-table [resizableColumns]="false" [autoLayout]="false" [value]="signupData.customerMappings" styleClass="mt-2 p-datatable-gridlines p-datatable-md p-datatable-sm"
        [rowHover]="true">
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th>
                    Kundtyp
                </th>

                <th>
                    Personnummer / Organisationsnummer
                </th>
                <th >
                    Anläggning
                </th>
                <th>

                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
                <td>
                    {{rowData.customerType.name}}
                </td>
                <td>
                    {{rowData.ssnOrg}}
                </td>
                <td >
                    <div style="width: 100%">
                    <p-multiSelect placeholder="Välj Anläggning"  emptyFilterMessage = "Hittas ej" [ngClass]="{'ng-invalid': rowData.selectedCompanies.length == 0,'': rowData.selectedCompanies.length == 0}" display="chip" appendTo="body" [options]="rowData.companies" [(ngModel)]="rowData.selectedCompanies" optionLabel="companyName">
                        <ng-template let-value pTemplate="selectedItems">
                            <div *ngIf="rowData.selectedCompanies && rowData.selectedCompanies.length == 1">{{ rowData.selectedCompanies.length }} Anläggning vald</div>
                            <div *ngIf="rowData.selectedCompanies && rowData.selectedCompanies.length > 1">{{ rowData.selectedCompanies.length }} Anläggningar valda</div>
                            <div *ngIf="!rowData.selectedCompanies || rowData.selectedCompanies.length < 1">Välj Anläggning</div>
                        </ng-template>
                        <ng-template let-companies pTemplate="item">
                            <div class="flex align-items-center gap-2">
                                <div>{{ companies.companyName }}</div>
                            </div>
                        </ng-template>
                    </p-multiSelect>
                    </div>
                </td>
                <td class="text-center">
                    <p-button label="Ta bort" (onClick)="deleteRow(rowData)" styleClass="p-button-danger"></p-button>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns>
            <tr>
                <td [attr.colspan]="4" style="text-align: center;">
                    Inga rader funna
                </td>
            </tr>
        </ng-template>
    </p-table>
    <small *ngIf="submittedAdd && (this.signupData.customerMappings?.length == 0)" class="p-error w-100">
        Kundkartläggning krävs </small>
</div>
<br/>
<div class="row mt-3">
    <div class="col-md-12 col-lg-6 text-start">
        <p-checkbox label="Godkännande av hantering av personuppgifter" [(ngModel)]="isAgreeToTerms"
            [binary]="true"></p-checkbox>
        <i class="pi pi-download ms-4" style="font-size: 1rem; cursor: pointer" (click)="downloadPolicy()"></i>
    </div>
    <div class="col-md-12 col-lg-6 text-end">
        <button type="button" class="btn btn-ouline-dark me-2" (click)="previousPage()">Tillbaka</button>
        <button
        (click)="submitData()"
        icon="pi pi-angle-right"
        iconPos="right"
        type="submit"
        class="btn btn-dark"
        [disabled]="!isAgreeToTerms">
        Fortsätt
      </button>
    </div>
</div>

<p-blockUI [target]="pnl" [blocked]="blockedDocument"></p-blockUI>
<div class="col-12">        
 <div style="cursor: pointer;" (click)="onClickHelpBtn()" #elm class="align-items-center d-flex d-inline-flex me-2"><i class="pi pi-question-circle me-2" style="font-size: 1rem;color: 'var(--primary-color)'" ></i> Instruktion</div>
</div>
<p-confirmDialog [acceptLabel]="'Ja'"
[rejectLabel]="'Nej'" rejectButtonStyleClass="p-button-outlined" acceptButtonStyleClass="p-button-warning" icon="pi pi-exclamation-triangle" [closable]="false"></p-confirmDialog>     
<p-toast position="top-right"></p-toast>

<p-dialog (onHide)="backToLogin()" header="" [(visible)]="visibleSuccessfullModal" [breakpoints]="{ '960px': '75vw' }" [style]="{ width: '41vw' }" [draggable]="false" [resizable]="false">
    <div class="text-center">
        <i class="pi pi-check-circle" style="color: #1da750; font-size: 3rem;"></i>    <br>
        <p class="p-sucessfull-modal-header">
            Din registreringsförfrågan är skickad.
        </p>
        <p>
            Kundservice kommer behandla din förfrågan så fort som möjligt. Håll utkik i mailkorgen.
        </p>
    </div>
    <div class="text-center">
        <p-button label="Ok" (onClick)="backToLogin()"
        styleClass="p-button-primary"></p-button>
    </div>
</p-dialog>

<p-dialog header="Koppla kunder till ditt konto i kundportalen" [(visible)]="visibleHelpModal" [breakpoints]="{ '960px': '90vw' }" [style]="{ width: '90vw' }" [draggable]="false" [resizable]="false">
  <div class="row"> <div class="col-12 col-lg-5">
    <div  class="mb-2 card p-2 shadow-sm">
        <b>Instruktion 1</b> 
        <p> Du väljer själv vilka kunder på vilka Vinning anläggningar du vill koppla till ditt konto i kundportalen. För att koppla en kund följ steg 1 till 4 i beskrivningen nedan.<br> Notera att du kan välja hur många kunder du vill och att du kan välja vissa eller alla Vinning-anläggningar för respektive kund. 
        </p>
    </div>

    <div class="mb-2 card p-2 shadow-sm">
        <b>Instruktion 2</b>  
        <div>För att koppla en kund till ditt konto i kundportalen<br>
           <div class="pt-2 fw-bold">  
                <ul class="list-none mb-0">
                    <li></li>
                    <li>Steg 1: Välj kundtyp</li>
                    <li>Steg 2: Ange Organisations- eller personnummer</li>
                    <li>Steg 3: Välj de Vinning-anläggningar du vill koppla</li>
                    <li>Steg 4: Klicka på "Lägg till" </li>
                </ul> 
        </div></div> 
    </div>
    <div class="mb-2 card p-2 shadow-sm">
        <b>Instruktion 3</b>  
        <div>  För att ta bort en kund alternativt lägga till eller ta bort en Vinning-anläggning.</div>
          <div class=" pt-2 fw-bold">   
            
             <ul class="list-none">
                <li>Steg 5: Öppna rulllistan och välj önskade Vinning-anläggning(ar)</li>     
                <li>Steg 6: Klicka på "Ta bort" för att ta bort en kund ur kopplingslistan</li>             
             </ul>   
        </div>
    </div>
    </div>
    <div class="col-12 col-lg-7">
       <div class="text-center"> <p-image src="../../../../../assets/imgs/SignupInstructionImg.jpg" alt="Image" width="750"></p-image>
       </div> <div class="mt-2 text-end me-4"> <p-button label="Stäng" (click)="closeInstructionPopup()"
            styleClass="p-button-primary"></p-button></div>
    </div>
</div>
</p-dialog>

<p-overlayPanel #panel>
    <ng-template pTemplate="content">
        <p>Koppla kunder till ditt konto i kundportalen</p>
    </ng-template>
</p-overlayPanel>
