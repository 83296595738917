import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { LayoutModule } from './modules/layout/layout.module';
import { RouterModule } from '@angular/router';
import { LoginModule } from './modules/login/login.module';
import { AppRoutingModule } from './app-routing.module';
import { SignUpModule } from './modules/sign-up/sign-up.module';
import { SummaryModule } from './modules/summary/summary.module';
import { TransactionModule } from './modules/transaction/transaction.module';
import { WasteCodeModule } from './modules/waste-code/waste-code.module';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { ResetPasswordModule } from './modules/reset-password/reset-password.module';
import { HttpClientModule } from '@angular/common/http';
import { httpInterceptorProviders } from './shared/helpers/http.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from 'ngx-spinner';
import { StoreModule } from '@ngrx/store';
import { rootReducer } from './store/app.root.reducer';
import { MessageService } from 'primeng/api';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { NumberFormatPipe } from './shared/pipes/number.pipe';

@NgModule({
  declarations: [
    AppComponent,
    NumberFormatPipe    
  ],
  imports: [
    RouterModule,
    BrowserModule,
    LayoutModule,
    HttpClientModule,
    LoginModule,
    SignUpModule,
    AppRoutingModule,
    SummaryModule,
    TransactionModule,
    WasteCodeModule,
    DashboardModule,
    ResetPasswordModule,
    BrowserAnimationsModule,
    ToastModule,
    TableModule,
    NgxSpinnerModule.forRoot({ type: 'ball-circus' }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
      registrationStrategy: 'registerWhenStable:30000'
    }),
    StoreModule.forRoot(rootReducer),
  ],
  providers: [httpInterceptorProviders, MessageService,NumberFormatPipe],
  bootstrap: [AppComponent],
  exports:[NumberFormatPipe]
})
export class AppModule{
}
