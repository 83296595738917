<form [formGroup]="customerDetailsForm">
  <div class="row">
    <div class="col-6">
      <div class="form-group mb-3">
        <label for="type" class="fw-bold label-required">Kundtyp</label>
        <div>
          <p-dropdown
            id="customerType"
            [options]="customerTypes"
            optionLabel="name"
            placeholder="Välj kundtyp"
            formControlName="customerType"
          ></p-dropdown>
        </div>
        <div class="error-wrapper"></div>
      </div>

      <div class="form-group mb-3">
        <label for="email " class="fw-bold label-required">Email</label>
        <input
          pInputText
          type="text"
          [id]="email"
          aria-describedby="Email"
          placeholder="Ange email"
          formControlName="email"
          [required]="true"
          (input)="convertToLowercase()"
        />
        <div *ngIf="email.invalid && (email.dirty || email.touched)" class="error-wrapper">
          <small *ngIf="email.errors['required']" class="p-error"
            >Email krävs</small
          >
          <small *ngIf="email.errors['pattern']" class="p-error"
            >Vänlig ange en giltig email</small
          >
          <small *ngIf="emaiExist" class="p-error">Angiven e-post address existerar redan</small>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="form-group mb-3">
        <div *ngIf="signupData.customerType?.id == 1">
          <label for="ssn-org" class="fw-bold label-required">Organisationsnummer</label>
          <p-inputMask
            [id]="ssnOrg"
            aria-describedby="Ssn"
            placeholder="Ange organisationsnummer"
            mask="999999-9999?9"
            [required]="true"
            formControlName="ssnOrg"
          ></p-inputMask>
        <div class="error-wrapper">  <small
            *ngIf="ssnOrg.invalid && (ssnOrg.dirty || ssnOrg.touched)"
            class="p-error"
            >Organisationsnummer krävs.</small
          ></div>
        </div>

        <div *ngIf="signupData.customerType?.id == 2 || !signupData.customerType?.id">
          <label for="ssn-org" class="fw-bold label-required">Personnummer</label>
          <p-inputMask
            [id]="ssnOrg"
            aria-describedby="Ssn"
            placeholder="Ange personnummer"
            mask="9999999999"
            [required]="true"
            formControlName="ssnOrg"
          ></p-inputMask>
          <div class="error-wrapper">  <small
            *ngIf="ssnOrg.invalid && (ssnOrg.dirty || ssnOrg.touched)"
            class="p-error"
            >Personnummer krävs.</small
          >
        </div> </div>
        </div>

        <div class="form-group mb-3">
          <label for="phone" class="fw-bold label-required">Telefonnummer</label>
          <input
          pInputText
          type="tel" inputmode="tel"
          id="phone"
          aria-describedby="Phone"
          placeholder="Ange telefonnummer" maxlength="14"
          formControlName="phone" (keypress)="mobileNumber($event)"
        />

      <div *ngIf="phone.invalid && (phone.dirty || phone.touched)" class="error-wrapper">
        <small *ngIf="phone.errors['required']" class="p-error">Telefonummer krävs</small>
   
        <small *ngIf="!phone.errors['required']" class="p-error">Ange giltigt telefonnummer</small>
      </div>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-md-12 col-lg-6 text-start">
        <button type="button" class="btn btn-ouline-dark me-2" (click)="previousPage()">Tillbaka</button>
      </div>
      <div class="col-md-12 col-lg-6 text-end">

        <button
        (click)="nextPage()"
        icon="pi pi-angle-right"
        iconPos="right"
        type="submit"
        class="btn btn-dark w-100 p-2 fw-bold"
        [disabled]="customerDetailsForm.invalid">
        Fortsätt
      </button>
      </div>
  </div>

</form>
