import { AppRouterPath } from 'src/app/shared/constants';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { SubjectService } from './shared/services/subject.service.';
import { AuthStatus } from './shared/models/state.model';
import { Store } from '@ngrx/store';
import { AuthService } from './shared/services/auth.service';
import { Router } from '@angular/router';
import { rehydrateProfile, reviseLoginFlowStatus } from './store/actions/app.auth.action';
import { MessageService } from 'primeng/api';
import { registerLocaleData } from '@angular/common';
import localeSv from '@angular/common/locales/sv';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  loading = true;
  state: AuthStatus;

  constructor(
    private subjectService: SubjectService,
    private spinnerService: NgxSpinnerService,
    private authService: AuthService,
    private store: Store<AuthStatus>,
    private router: Router,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    registerLocaleData(localeSv, 'lang');
    this.subjectService.emitLoading.subscribe(async (value) => {
     if(value == true)   this.spinnerService.show();
     else this.spinnerService.hide();
    });

    this.subjectService.emit500ErrorSubject.subscribe(async (value) => {
      if(value == true)  this.messageService.add({
        severity: "error",
        summary: "Oops, Something went wrong",
        detail: "Try to refresh this page or feel free to contact us if the problem persists",
      }); 
     });

    if(this.authService.authenticated()) {
      this.store.dispatch(rehydrateProfile());
      this.store.select(state => state['authStatus']['loginFlowCompleted']).subscribe((value: boolean) => {
        if(value && window.location.href.includes('login')) {
          this.router.navigateByUrl(`/${AppRouterPath.dashboard}`);
        } else if (!value){
          this.router.navigateByUrl(`/${AppRouterPath.selectCustomer}`);
        }
      });
    } else {
      this.store.dispatch(reviseLoginFlowStatus(false));
    }
  }
}
