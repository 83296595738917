import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CostCenter } from '../models/common.model';


const COMMON_API = `${environment.apiUrl}/common`;

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  getArticleList(customerId: string | number): Observable<any> {
     return this.http.get(
      `${COMMON_API}/get-article-list/${customerId}`
    );
  }

  constructor(private http: HttpClient) { }

  getYardsByCompanyId = (companyId: string | number): Observable<any> => {
    return this.http.get(
      `${COMMON_API}/get-yard-list/${companyId}`
    );
  }

  export = (companyId: string | number): Observable<any> => {
    return this.http.get(
      `${COMMON_API}/get-yard-list/${companyId}`
    );
  }

  getYardsByCustomerIdAndCompanyId(customerId: string | number, companyId: string | number) {
    return this.http
    .get<any>(`${COMMON_API}/get-customer-yard-list/${customerId}/${companyId}`)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  getCostCentersByCustomerId = (customerId: string | number): Observable<any> => {
    return this.http.get(
      `${COMMON_API}/get-cost-center-list/${customerId}`
    );
  }

  getCostCentersByCustomerIdAndCompanyId(customerId: string | number, companyId: string | number) : Observable<CostCenter[]>{
    return this.http
    .get<any>(`${COMMON_API}/get-customer-cost-center-list/${customerId}/${companyId}`)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  getArticleGroups = (): Observable<any> => {
    return this.http.get(
      `${COMMON_API}/get-article-group-list`
    );
  }

  getArticleGroupsByCustomerIdAndCompanyId = (customerId: string | number, companyId: string | number): Observable<any> => {
    return this.http
    .get<any>( `${COMMON_API}/get-customer-article-group-list/${customerId}/${companyId}`)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  getArticleSubGroupsByGroupId = (groupId: string | number): Observable<any> => {
    return this.http.get(
      `${COMMON_API}/get-article-sub-group-list/${groupId}`
    );
  }

  getArticleSubGroupsByGroupIdAndCustomerIdAndCompanyId = (groupIds: string | number, customerId: string | number, companyId: string | number): Observable<any> => {
    return this.http.get(
      `${COMMON_API}/get-customer-article-sub-group-list/${groupIds}/${customerId}/${companyId}`
    );
  }
}
