<div class="row bg-white p-3 shadow-sm my-3 justify-content-start">
    <div style="text-align: right">
        <a (click)="export()" class="btn btn-light shadow-sm p-2 me-3 small">
            <i class="pi pi-file-excel btn-operations color-primary"></i><br />
            <label class="form-label small mb-0">Exportera</label>
          </a>
    </div>
<p-treeTable [value]="gridData.results" 
styleClass="mt-2 p-treetable-gridlines p-treetable-sm  p-datatable-sm" [rowHover]="true" [customSort]="true"
expandableRowGroups="true" stateStorage="local" stateKey="statedemo-local" [showCurrentPageReport]="true"
currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" (sortFunction)="customSort($event)"   
sortField="{{tblFilters.orderBy}}" 
[sortOrder]="tblFilters.orderByTblVal">
    <ng-template pTemplate="header">
        <tr>
                <th  [ttSortableColumn]="sortingColFieldName">
                    <span *ngIf="isEWCWasteCodeGrid">EWC - Avfallskod</span>
                    <span *ngIf="!isEWCWasteCodeGrid">RD - Avfallskod</span> 
                    <p-treeTableSortIcon  [field]="sortingColFieldName"></p-treeTableSortIcon>
                </th>
                <th>
                    Artikel
                </th>
                <th class="text-end">
                    Vikt <small>(kg)</small>
                </th>
        </tr>
        <tr>
            <th>
                <input type="text" *ngIf="isEWCWasteCodeGrid" pInputText placeholder="Ange EWC Avfallskod" (keyup.enter)="onChangedFilters()"
                    [(ngModel)]="tblFilters.wasteCode">

                <input type="text" *ngIf="!isEWCWasteCodeGrid" pInputText placeholder="Ange RD Avfallskod" (keyup.enter)="onChangedFilters()"
                    [(ngModel)]="tblFilters.wasteCode">
            </th>
            <th>
                <input type="text" pInputText placeholder="Ange artikelnummer eller beskrivning" (keyup.enter)="onChangedFilters()"
                    [(ngModel)]="tblFilters.articleNumber">
            </th>
            <th></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
        <tr [ttRow]="rowNode">
            <td>
                <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                {{rowData.wasteCode}}
            </td>
            <td><span *ngIf="rowData.articleNumber">{{rowData.articleNumber}} ({{rowData.description}})</span></td>
            <td class="text-end">{{rowData.sum_Weight | number: '1.0-0':'lang'}}</td>
        </tr>
    </ng-template>
    <ng-template *ngIf="gridData.results" pTemplate="footer">
        <tr>
            <td colspan="2" class="text-left">Summa</td>
            <td class="text-end">{{summaryTotals.weight | number: '1.0-0':'lang'}}</td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="3" class="text-center">Ingen information hittad.</td>
        </tr>
    </ng-template>
</p-treeTable>
    <p-paginator [showCurrentPageReport]="true"
        currentPageReportTemplate="Visar {first} till {last} av {totalRecords} rader"
        (onPageChange)="onPageChange($event)" [first]="pageNumber" [rows]="10"
        [totalRecords]="gridData.totalNumberOfRecords" [rowsPerPageOptions]="[10, 20, 30]"></p-paginator>
</div>