import { Component, Inject, LOCALE_ID } from '@angular/core';
import { CommonService } from '../../../shared/services/common.service';
import {
  AuthStatus,
  SelectedProfile,
} from '../../../shared/models/state.model';
import { Store } from '@ngrx/store';
import {
  Yard,
  CostCenter,
  ArticleGroup,
  ArticleSubGroup,
  SummaryElement,
  SummaryElementsTotal,
  FilteredSummaryRequest,
} from '../../../shared/models/common.model';
import { PagedResponse } from '../../../shared/models/response.model';
import { SummaryService } from '../services/summary.service';
import { lastValueFrom } from 'rxjs';
import { DownloadService } from 'src/app/shared/services/download-service';
import { ChartColourCodes } from 'src/app/shared/models/chart-colour-codes';
import { ChartDataModel } from 'src/app/shared/models/chart-data.model';
import { TitleService } from 'src/app/shared/services/title.service';
import { formatDate } from '@angular/common';
import { CustomerType } from 'src/app/shared/constants';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.css'],
})
export class SummaryComponent {
  fromDate: any;
  toDate: any;
  selectedProfile: SelectedProfile;
  selectedArticleGroups: ArticleGroup[];
  selectedArticleSubGroups: ArticleSubGroup[];
  selectedYards: Yard[];
  selectedCostCenters: CostCenter[];

  articleGroups: ArticleGroup[];
  articleSubGroups: ArticleSubGroup[];
  costCenters: CostCenter[];
  yards: Yard[];
  visibleChart: boolean = false;

  filters: FilteredSummaryRequest;

  chartData: ChartDataModel;
  chartTblData: any;
  chartTblDataTotal: Number;

  summaryData: PagedResponse<SummaryElement[]> = {
    results: [],
    pageNumber: 0,
    pageSize: 0,
    total: 0,
    orderBy: '',
    sortOrder: '',
    totalNumberOfRecords: 0,
  };

  summaryTotals: SummaryElementsTotal = {
    weight: 0,
    vatExclusiveAmount: 0,
  };
  pageNumber = 0;
  sortOrder = 1;

  constructor(
    private downloadService: DownloadService,
    private commonService: CommonService,
    private summaryService: SummaryService,
    private store: Store<AuthStatus>,
    private titleService: TitleService,
    @Inject(LOCALE_ID) private locale: string
  ) {}

  ngOnInit() {
    this.setDefaultFilters();
    this.titleService.setTitle('Material');
    this.store
      .select(
        (state) =>
          (this.selectedProfile = state['authStatus']['selectedProfile'])
      )
      .subscribe();
    this.fetchCostCenters();
    this.fetchYards();
    this.fetchArticleGroups();
    if (this.articleGroups) {
      this.fetchArticleSubGroups(
        this.articleGroups.map((elem) => elem.id).join(',')
      );
    }
    this.fetchTableData();
  }

  get isIndividualCustomer(): boolean {
    if (this.selectedProfile.customerTypeId == CustomerType.Individual) {
      return true;
    }
    return false;
  }

  setDefaultFilters() {
    this.filters = {
      articleNumber: '',
      startDate: null,
      endDate: null,
      pageSize: 10,
      page: 1,
      orderBy: 'ArticleGroupName',
      sortOrder: 'ASC',
      articleGroupIds: '',
      articleSubGroupIds: '',
      costCenterIds: '',
      yardIds: '',
      smartCompanyId: 0,
      smartCustomerId: 0,
      articleDescription: '',
    };
  }

  fetchArticleGroups() {
    this.commonService
      .getArticleGroupsByCustomerIdAndCompanyId(
        this.selectedProfile.customerId,
        this.selectedProfile.companyId
      )
      .subscribe((data) => {
        this.articleGroups = data;
      });
  }

  fetchArticleSubGroups = (articleGroupId: string) => {
    if (!this.selectedArticleGroups || this.selectedArticleGroups.length == 0) {
      this.articleSubGroups = [];
    }

    this.commonService
      .getArticleSubGroupsByGroupIdAndCustomerIdAndCompanyId(
        articleGroupId,
        this.selectedProfile.customerId,
        this.selectedProfile.companyId
      )
      .subscribe({
        next: (data: ArticleSubGroup[]) => {
          this.articleSubGroups = data;
        },
      });
  };

  fetchCostCenters() {
    this.commonService
      .getCostCentersByCustomerIdAndCompanyId(
        this.selectedProfile.customerId,
        this.selectedProfile.companyId
      )
      .subscribe((data) => {
        this.costCenters = data;
      });
  }

  onPageChange(value) {
    this.filters.page = value.page + 1;
    this.filters.pageSize = value.rows;
    this.fetchTableData();
  }

  export() {
    this.summaryService.export(this.setFilterData()).subscribe({
      next: (data: any) => {
        var base64 = this.downloadService.base64ToArrayBuffer(data);
        this.downloadService.saveByteArray(
          'Export Vinning kundportal - Material',
          base64
        );
      },
    });
  }

  clear() {
    this.selectedCostCenters = new Array<CostCenter>();
    this.selectedYards = new Array<Yard>();
    this.toDate = null;
    this.fromDate = null;
    this.selectedArticleGroups = new Array<ArticleGroup>();
    this.selectedArticleSubGroups = new Array<ArticleSubGroup>();
    this.filters.articleNumber = null;
    this.sortOrder = 1;
    this.setDefaultFilters();
    this.fetchTableData();
  }

  onChangedArticleNumberFilter() {
    this.filters.page = 1;
    this.fetchTableData();
  }

  getChartData() {
    this.summaryService.getChart(this.setFilterData()).subscribe({
      next: (data: any) => {
        let count = 0;
        this.chartTblData = data;
        this.chartData = new ChartDataModel();
        this.chartTblDataTotal = 0;
        var colours = new ChartColourCodes();

        for (var res of data) {
          if (colours.colourCodes.length == count + 1) {
            count = 0;
          }
          this.chartData.labels.push(res.articleSubGroupName);
          this.chartData.datasets[0].data.push(res.sum_Weight);
          if (res.sum_Weight) {
            this.chartTblDataTotal = res.sum_Weight + this.chartTblDataTotal;
          }
          this.chartData.datasets[0].backgroundColor.push(
            colours.colourCodes[count]
          );
          this.chartData.datasets[0].hoverBackgroundColor.push(
            colours.colourCodes[count]
          );
          count = count + 1;
        }
        this.visibleChart = true;
      },
    });
  }

  fetchYards() {
    this.commonService
      .getYardsByCustomerIdAndCompanyId(
        this.selectedProfile.customerId,
        this.selectedProfile.companyId
      )
      .subscribe((data) => {
        this.yards = data;
      });
  }

  fetchTableData = () => {
    this.summaryService.getPagedSummaryResults(this.setFilterData()).subscribe({
      next: (data: PagedResponse<SummaryElement[]>) => {
        data.pageNumber = data.pageNumber - 1;
        this.summaryData = data;
        this.calculateTableTotal(data);
      },
    });
  };

  calculateTableTotal(data: PagedResponse<SummaryElement[]>) {
    this.summaryTotals.vatExclusiveAmount = 0;
    this.summaryTotals.weight = 0;
    for (var res of data.results) {
      this.summaryTotals.vatExclusiveAmount =
        this.summaryTotals.vatExclusiveAmount + res.sum_VATExclusiveAmount;
      this.summaryTotals.weight = this.summaryTotals.weight + res.sum_Weight;
    }

    this.summaryTotals.weight = Math.round(this.summaryTotals.weight);
    this.summaryTotals.vatExclusiveAmount = Math.round(
      this.summaryTotals.vatExclusiveAmount
    );
  }

  setFilterData(): FilteredSummaryRequest {
    this.filters.smartCompanyId = Number(this.selectedProfile.companyId);
    this.filters.smartCustomerId = Number(this.selectedProfile.customerId);

    return this.filters;
  }

  customSort(value) {
    if (
      value.field != this.filters.orderBy ||
      value.order != (this.filters.sortOrder == 'ASC' ? 1 : -1)
    ) {
      this.filters.orderBy = value.field;
      this.filters.sortOrder = value.order == 1 ? 'ASC' : 'DESC';
      this.fetchTableData();
    }
  }

  onChangedArticleDescriptionFilter() {
    this.filters.page = 1;
    this.fetchTableData();
  }

  onFromDateChanged = () => {
    if (this.fromDate) {
      this.filters.startDate = formatDate(
        new Date(this.fromDate),
        'yyyy-MM-dd',
        this.locale
      );
    }
    this.filters.page = 1;
    this.fetchTableData();
  };

  onToDateChanged = () => {
    if (this.toDate) {
      this.filters.endDate = formatDate(
        new Date(this.toDate),
        'yyyy-MM-dd',
        this.locale
      );
    }
    this.filters.page = 1;
    this.fetchTableData();
  };

  onArticleGroupSelectionChange = () => {
    this.filters.articleGroupIds = this.selectedArticleGroups
      .map((elem) => elem.id)
      .join(',');

    if (this.selectedArticleGroups.length < 1) {
      if (this.articleGroups) {
        this.fetchArticleSubGroups(
          this.articleGroups.map((elem) => elem.id).join(',')
        );
      }
    } else {
      this.fetchArticleSubGroups(
        this.selectedArticleGroups.map((elem) => elem.id).join(',')
      );
    }

    this.selectedArticleSubGroups = [];
    this.filters.articleSubGroupIds = null;
    this.filters.page = 1;
    this.fetchTableData();
  };

  onArticleSubGroupSelectionChange = () => {
    this.filters.articleSubGroupIds = this.selectedArticleSubGroups
      .map((elem) => elem.id)
      .join(',');
    this.filters.page = 1;
    this.fetchTableData();
  };

  onYardSelectionChange = () => {
    this.filters.yardIds = this.selectedYards.map((elem) => elem.id).join(',');
    this.fetchTableData();
    this.filters.page = 1;
  };

  onCostCenterSelectionChange = () => {
    this.filters.costCenterIds = this.selectedCostCenters
      .map((elem) => elem.id)
      .join(',');
    this.fetchTableData();
    this.filters.page = 1;
  };
}
