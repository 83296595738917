import { createAction } from "@ngrx/store";
import { SelectedProfile } from "src/app/shared/models/state.model";


export const resetAuthStatus = createAction('[Auth status] reset');

export const reviseLoginFlowStatus = createAction('[Login flow status] update', (loginFlowCompleted: boolean) => ({loginFlowCompleted}));

export const reviseSelectedProfile = createAction('[Selected profile] update', (selectedProfile: SelectedProfile) => ({selectedProfile}));

export const persistProfile = createAction('[Selected profile] persist');

export const rehydrateProfile = createAction('[Selected profile] reload from session storage');
