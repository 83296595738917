<div class="container-fluid d-flex align-items-stretch login-bg">
  <div class="login-container d-flex align-items-center w-100 justify-content-center">
    <div class="row w-50 bg-white shadow-sm">
        <div class="col-12 col-sx-12 col-sm-12 col-md-6 login-wrapper loginbg-img d-flex align-items-end text-end p-5">
           <div> <img src="assets/imgs/vinning_white.png" alt="" class="img-fluid fadeIn">
            <p class="text-white">Vi rustar en traditionell bransch inom järn- och metallåtervinning för morgondagens behov</p></div>
        </div>
        <div class="col-12 col-sx-12 col-sm-12 col-md-6 login-wrapper p-5 d-flex align-items-center" >
           <div class=" w-100"> <h2 class="mb-4 fadeIn">Välj kund</h2>
            <div class="form-group mb-3">
              <label for="CustomerName" class="fw-bold">Kundnamn</label>
              <p-dropdown styleClass="w-100" placeholder="Välj kund" [options]="customers" [(ngModel)]="selectedCustomer" (ngModelChange)="onCustomerSelectionChange()" optionLabel="displayText"></p-dropdown>
            </div>
            <div class="form-group mb-3">
              <label for="Company" class="fw-bold">Anläggning</label>
              <p-dropdown styleClass="w-100" placeholder="Välj Anläggning" [options]="companies" [(ngModel)]="selectedCompany" optionLabel="companyName"></p-dropdown>
            </div>
            <button pButton [disabled]="invalidInputs" (click)="onSubmit($event)" type="button" class="btn btn-dark w-100 p-2 fw-bold">Fortsätt</button>
        </div></div>
    </div>
  </div>
</div>
