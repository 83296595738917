import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { CustomerType } from 'src/app/shared/constants';
import { ChartColourCodes } from 'src/app/shared/models/chart-colour-codes';
import { ChartDataModel } from 'src/app/shared/models/chart-data.model';
import {
  CostCenter,
  FilteredSummaryRequest,
  Yard,
} from 'src/app/shared/models/common.model';
import { AuthStatus } from 'src/app/shared/models/state.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { DownloadService } from 'src/app/shared/services/download-service';
import { SummaryService } from '../../summary/services/summary.service';
import { TransactionService } from '../../transaction/services/transaction.service';
import { ChartConfigService } from '../services/chart-config.service';
import { FilteredTransactionTableRequest, TransactionWeightSummeryData } from '../../transaction/models/transaction-summery-data';
import { TitleService } from 'src/app/shared/services/title.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  selectedYards: Yard[];
  selectedCostCenters: CostCenter[];
  stackBaroptions=null;
  filters: FilteredTransactionTableRequest = {
    articleIds: null,
    purchaseNumber: '',
    pageSize: 10,
    page: 1,
    orderBy: 'purchaseDate',
    sortOrder: 'DESC',
    smartCompanyId: 0,
    smartCustomerId: 0,
    costCenterIds:null,
    yardIds:null,
    startDate: null,
    endDate: null
  };

  summeryfilters: FilteredSummaryRequest = {
    articleNumber: '',
    startDate: null,
    endDate: null,
    pageSize: 10,
    page: 1,
    orderBy: 'articleGroupId',
    sortOrder: 'ASC',
    articleGroupIds: '',
    articleSubGroupIds: '',
    costCenterIds: '',
    yardIds: '',
    smartCompanyId: 0,
    smartCustomerId: 0,
    articleDescription:''
  };

  data: any;

  chartOptions: any;

  subscription: Subscription;

  config: any;
  basicData = {};
  valuePerMonthData = {};
  basicOptions = {
    plugins: {
      legend: {
        labels: {
          color: '#000000',
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: '#000000',
        },
        grid: {
          color: 'rgba(255,255,255,0.2)',
        },
      },
      y: {
        ticks: {
          color: '#000000',
        },
        grid: {
          color: 'rgba(255,255,255,0.2)',
        },
      },
    },
  };

  selectedProfile: any;
  costCenters: CostCenter[];
  yards: Yard[];

  chartData: ChartDataModel;
  chartDataSource: any[];
  weightChartData = {};
  visibleChart=true;

  constructor(
    private configService: ChartConfigService,
    private commonService: CommonService,
    private transactionService: TransactionService,
    private summaryService: SummaryService,
    private downloadService: DownloadService,
    private datePipe: DatePipe,
    private store: Store<AuthStatus>,
    private titleService:TitleService,
    private router: Router
  ) {}

  ngOnInit() {
    this.setStackBarOptions();
    this.titleService.setTitle("Instrumentbräda");
    this.store
      .select(
        (state) =>
          (this.selectedProfile = state['authStatus']['selectedProfile'])
      )
      .subscribe();
    this.fetchCostCenters();
    this.fetchYards();

    this.data = {
      labels: ['A', 'B', 'C'],
      datasets: [
        {
          data: [300, 50, 100],
          backgroundColor: ['#42A5F5', '#66BB6A', '#FFA726'],
          hoverBackgroundColor: ['#64B5F6', '#81C784', '#FFB74D'],
        },
      ],
    };

    this.config = this.configService.config;
    this.updateChartOptions();
    this.subscription = this.configService.configUpdate$.subscribe((config) => {
      this.config = config;
      this.updateChartOptions();
    });

    this.loadData();
    this.loadChartData();
    this.loadvaluePerMonthData();
    this.loadWeightPerMonthData();
  }
  
  @HostListener('window:resize', ['$event'])
  refreshCharts(){
    this.visibleChart = false;
    setTimeout(() => {
      this.visibleChart = true;
    }, 100);
  }

  setStackBarOptions(){
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
    this.stackBaroptions = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
          tooltips: {
              mode: 'index',
              intersect: false
          },
          legend: {
              labels: {
                  color: textColor
              }
          }
      },
      scales: {
          x: {
              stacked: true,
              ticks: {
                  color: textColorSecondary
              },
              grid: {
                  color: surfaceBorder,
                  drawBorder: false
              }
          },
          y: {
              stacked: true,
              ticks: {
                  color: textColorSecondary
              },
              grid: {
                  color: surfaceBorder,
                  drawBorder: false
              }
          }
      }
  };
  }

  updateChartOptions() {
    this.chartOptions =
      this.config && this.config.dark
        ? this.getDarkTheme()
        : this.getLightTheme();
  }

  getLightTheme() {
    return {
      plugins: {
        legend: {
          labels: {
            color: '#000000',
          },
        },
      },
    };
  }

  getDarkTheme() {
    return {
      plugins: {
        legend: {
          labels: {
            color: '#000000',
          },
        },
      },
    };
  }

  get isIndividualCustomer():boolean{
    if(this.selectedProfile.customerTypeId == CustomerType.Individual){
      return true;
    }
    return false;
  }

  fetchCostCenters = () => {
    this.commonService
      .getCostCentersByCustomerIdAndCompanyId(
        this.selectedProfile.customerId,
        this.selectedProfile.companyId
      )
      .subscribe({
        next: (data: CostCenter[]) => {
          this.costCenters = data;
        },
      });
  };

  fetchYards = () => {
    this.commonService
      .getYardsByCustomerIdAndCompanyId(
        this.selectedProfile.customerId,
        this.selectedProfile.companyId
      )
      .subscribe({
        next: (data: Yard[]) => {
          this.yards = data;
        },
      });
  };

  onYardSelectionChange = () => {
    this.filters.yardIds = this.selectedYards.map((elem) => elem.id).join(',');
    this.summeryfilters.yardIds = this.selectedYards.map((elem) => elem.id).join(',');

    this.loadData();
    this.loadChartData();
    this.loadvaluePerMonthData();
    this.loadWeightPerMonthData();
  };

  onCostCenterSelectionChange = () => {
    this.filters.costCenterIds = this.selectedCostCenters
      .map((elem) => elem.id)
      .join(',');
      this.summeryfilters.costCenterIds = this.selectedCostCenters
      .map((elem) => elem.id)
      .join(',');

    this.loadData();
    this.loadChartData();
    this.loadvaluePerMonthData();
    this.loadWeightPerMonthData();
  };

  setFilterData() {
    this.filters.smartCompanyId = Number(this.selectedProfile.companyId);
    this.filters.smartCustomerId = Number(this.selectedProfile.customerId);

    this.summeryfilters.smartCompanyId = Number(this.selectedProfile.companyId);
    this.summeryfilters.smartCustomerId = Number(this.selectedProfile.customerId);
  }

  loadData() {
    this.setFilterData();
    this.transactionService
      .getTransactionSummeryData(this.filters)
      .subscribe((data) => {
        var dataArray = data.map(p=> p.transactionCount);
        var labelArray =data.map(p=> p.monthName);

        this.basicData = {
          labels: labelArray,
          datasets: [
            {
              label: 'Antal transaktioner',
              backgroundColor: '#42A5F5',
              data: dataArray,
            },
          ],
        };
      });
  }

  loadvaluePerMonthData() {
    this.setFilterData();
    this.transactionService
      .getTransactionValueSummeryData(this.filters)
      .subscribe((data) => {
        var dataArray = data.map(p=> p.sumVATExclusiveAmount);
        var labelArray =data.map(p=> p.monthName);
        this.valuePerMonthData = {
          labels: labelArray,
          datasets: [
            {
              label: 'Total Värde',
              backgroundColor: '#66BB6A',
              data: dataArray,
            },
          ],
        };
      });
  }

  gotoPage(url){
    this.router.navigate([url]);
  }

  loadWeightPerMonthData() {
    this.setFilterData();
    this.transactionService
      .getTransactionWeightSummeryData(this.filters)
      .subscribe((data) => {
        var dataArray = data.map(p=> p.totalWeight);
        var labelArray =data.map(p=> p.monthName);
        this.weightChartData = {
          labels: labelArray,
          datasets: [
            {
              label: 'Levererad vikt',
              backgroundColor: '#FFA726',
              data: dataArray,
            },
          ],
        };
      });
  }

  loadChartData() {
    this.setFilterData();
    this.summaryService.getChart(this.summeryfilters).subscribe({
      next: (data: any) => {
        let count = 0;
        var chartData = new ChartDataModel();
        for (var res of data) {
          chartData.labels.push(res.articleSubGroupName);
          chartData.datasets[0].data.push(res.sum_Weight);
          var colours = new ChartColourCodes();
          chartData.datasets[0].backgroundColor.push(
            colours.colourCodes[count]
          );
          chartData.datasets[0].hoverBackgroundColor.push(
            colours.colourCodes[count]
          );
          count = count + 1;
        }
        this.chartData = chartData;
        this.chartDataSource = data;
      },
    });
  }

  getChartTotal(): number{
    return this.chartDataSource?.reduce((sum, current) => sum + current.sum_Weight, 0);
  }

  export() {
    this.setFilterData();
    this.summaryService.export(this.summeryfilters).subscribe({
      next: (data: any) => {
        var base64 = this.downloadService.base64ToArrayBuffer(data);
        this.downloadService.saveByteArray(
          'Export Vinning kundportal - Material '+this.datePipe.transform(Date.now(), 'ddMMYYYY'),
          base64
        );
      },
    });
  }

  exportTransaction(){
    this.transactionService.exportTransactions(this.filters).subscribe({
      next: (data: any) => {
        var base64 = this.downloadService.base64ToArrayBuffer(data);
        this.downloadService.saveByteArray(
          'Export Vinning kundportal - Transaktioner '+this.datePipe.transform(Date.now(), 'ddMMYYYY'),
          base64
        );
      },
    });
  }
}
