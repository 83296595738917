import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './components/layout/layout.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { RouterModule } from '@angular/router';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { MenubarModule } from 'primeng/menubar';
import { MenuModule } from 'primeng/menu';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ButtonModule } from 'primeng/button';

@NgModule({
  declarations: [LayoutComponent, FooterComponent, HeaderComponent],
  imports: [
    RouterModule,
    TooltipModule.forRoot(),
    CommonModule,
    MenubarModule,
    MenuModule,
    BrowserAnimationsModule,
    ButtonModule
  ],
  entryComponents: [],
  exports: [LayoutComponent, FooterComponent, HeaderComponent],
  providers: []
})
export class LayoutModule { }
