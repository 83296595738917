import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PaginatorModule } from 'primeng/paginator';
import {TableModule} from 'primeng/table';
import {ToastModule} from 'primeng/toast';
import {CalendarModule} from 'primeng/calendar';
import {SliderModule} from 'primeng/slider';
import {MultiSelectModule} from 'primeng/multiselect';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DialogModule} from 'primeng/dialog';
import {ButtonModule} from 'primeng/button';
import {DropdownModule} from 'primeng/dropdown';
import {InputTextModule} from 'primeng/inputtext';

import { SummaryRoutingModule } from './summary-routing.module';
import { SummaryComponent } from './components/summary.component';
import { TransactionRoutingModule } from '../transaction/transaction-routing.module';
import { ChartModule } from 'primeng/chart';
import { SummaryChartComponent } from './components/summary-chart/summary-chart.component';

@NgModule({
  declarations: [
    SummaryComponent,
    SummaryChartComponent
  ],
  imports: [
    CommonModule,
    SummaryRoutingModule,
    CommonModule,
    TransactionRoutingModule,
    PaginatorModule,
    TableModule,
    TableModule,
    CalendarModule,
		SliderModule,
		DialogModule,
		MultiSelectModule,
		ContextMenuModule,
		DropdownModule,
		ButtonModule,
		ToastModule,
    InputTextModule,
    ChartModule
  ]
})
export class SummaryModule { }
