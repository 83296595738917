
<div class="container-fluid d-flex align-items-stretch login-bg">
    <div class="login-container d-flex align-items-center w-100 justify-content-center">
        <div class="row w-85 bg-white shadow-sm">
            <div
                class="col-12 col-sx-12 col-sm-12 col-md-4 login-wrapper loginbg-img d-flex align-items-end text-end p-5">
                <div> <img src="assets/imgs/vinning_white.png" alt="" class="img-fluid fadeIn">
                    <p class="text-white">Vi rustar en traditionell bransch inom järn- och metallåtervinning för
                        morgondagens behov</p>
                </div>
            </div>
            <div class="col-12 col-sx-12 col-sm-12 col-md-8 login-wrapper px-0">
                <div class="mb-auto w-100 pt-4 px-5 bg-light pb-3">
                    <h5 class="text-center fw-bold mb-3">Skapa konto
                    </h5> <p-steps [model]="items" [readonly]="true"></p-steps></div>
                <div class="w-100 d-flex align-items-start flex-column pt-5 pb-5 px-5">
                    <div class="w-100"><router-outlet></router-outlet></div>
                </div>
            </div>
        </div>
    </div>
</div>