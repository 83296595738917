import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HTTP_INTERCEPTORS, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, catchError, filter, finalize, Observable, switchMap, take, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { TokenResponse } from '../models/auth.model';
import { HTTPMonitoringService } from '../services/http-monitoring.service';
import { SubjectService } from '../services/subject.service.';
import { environment } from 'src/environments/environment';


const TOKEN_HEADER_KEY = "Authorization";

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private authService: AuthService, private httpMonitoringService: HTTPMonitoringService,private subjectService: SubjectService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq = req;
    const token = this.authService.getAccessToken();
    if (token != null && !authReq.url.includes(environment.gdprUrl)) {
      authReq = this.addTokenHeader(req, token);
    }

    this.httpMonitoringService.start();

    return next.handle(authReq).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && (!authReq.url.includes('/login') && !authReq.url.includes('/reset-password'))
      && error.status === 401) {
        return this.handle401Error(authReq, next);
      }else if (error.status === 500){
        this.subjectService.error500Occurred(true);
      }
      return throwError(error);
    }),finalize(() => {
      console.log('After request');
      this.httpMonitoringService.stop();
  }));
  }

  private handle401Error = (request: HttpRequest<any>, next: HttpHandler) => {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const refreshToken = this.authService.getRefreshToken();
      const accessToken = this.authService.getAccessToken();

      if (refreshToken) {
        return this.authService.refreshToken(accessToken,refreshToken).pipe(
          switchMap((tokenResponse: TokenResponse) => {
            this.isRefreshing = false;
            this.authService.saveTokens(tokenResponse);
            this.refreshTokenSubject.next(tokenResponse.accessToken);
            return next.handle(this.addTokenHeader(request, tokenResponse.accessToken));
          }),
          catchError((err) => {
            this.isRefreshing = false;
            this.httpMonitoringService.forceStop();
            this.authService.logout();
            return throwError(err);
          })
        );
      }
    } else {
      this.httpMonitoringService.forceStop();
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((jwt) => {
          return next.handle(this.addTokenHeader(request, jwt));
        })
      );
    }
    return throwError("Invalid request!");
  }

  private addTokenHeader = (request: HttpRequest<any>, token: string) => {
    return request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, `Bearer ${token}`) });
  }
}

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
];
