import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppRouterPath } from '../../shared/constants';
import { CustomerDetailsComponent } from './components/customer-details/customer-details.component';
import { SignUpComponent } from './components/sign-up.component';
import { CustomerMapComponent } from './components/customer-map/customer-map.component';
import { SignUpData } from './models/sign-up.data';
import { DeclineSignupRequestComponent } from './components/decline-signup-request/decline-signup-request.component';
import { AcceptSignupRequestComponent } from './components/accept-signup-request/accept-signup-request.component';
import { SignUpRequestsComponent } from './components/sign-up-requests/sign-up-requests.component';

const routes: Routes = [
  { path: AppRouterPath.signup, component: SignUpComponent,
children : [
  { path: '', redirectTo: AppRouterPath.customerDetails, pathMatch: 'full' },
  { path: AppRouterPath.customerDetails, component: CustomerDetailsComponent },
  { path: AppRouterPath.customerMapping, component: CustomerMapComponent }
]}, 
{ path: AppRouterPath.signupDecline, component: DeclineSignupRequestComponent },
{ path: AppRouterPath.signupAccept, component: AcceptSignupRequestComponent },
{ path: AppRouterPath.signupRequests, component: SignUpRequestsComponent },];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SignUpRoutingModule { }
