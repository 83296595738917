export class ChartColourCodes {
  colourCodes: string[] = new Array<string>();
  
  constructor (){
    this.colourCodes.push("#42A5F5");
    this.colourCodes.push("#66BB6A");
    this.colourCodes.push("#FFA726");
    this.colourCodes.push("#DC143C");
    this.colourCodes.push("#BA55D3");
    this.colourCodes.push("#8B008B");
    this.colourCodes.push("#00008B");
    this.colourCodes.push("#2F4F4F");
    this.colourCodes.push("#FFEBCD");
    this.colourCodes.push("#BC8F8F");
    this.colourCodes.push("#FFB74D");
    this.colourCodes.push("#81C784");
    this.colourCodes.push("#E9967A");
    this.colourCodes.push("#FF00FF");
    this.colourCodes.push("#64B5F6");
    this.colourCodes.push("#32CD32");
    this.colourCodes.push("#00FA9A");
    this.colourCodes.push("#00FF7F");
    this.colourCodes.push("#008000");
    this.colourCodes.push("#FF4500");
    this.colourCodes.push("#FFD700");
    this.colourCodes.push("#00FFFF");
    this.colourCodes.push("#7FFF00");
    this.colourCodes.push("#DC143C");
    this.colourCodes.push("#8B0000");
    this.colourCodes.push("#A52A2A");
    this.colourCodes.push("#800000");
    this.colourCodes.push("#FFFAFA");
    this.colourCodes.push("#696969");
    this.colourCodes.push("#000080");
  }
}
