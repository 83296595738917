<div class="container-fluid pb-1 px-5 mt-5 pt-4">
    <div class="row mt-3">
        <div class="col-sm-12">
            <h5 class="fw-bold">Avfallskoder</h5>
        </div>
    </div>

    <div class="row bg-white p-3 shadow-sm my-3 justify-content-start">
        <div class="col-2">
            <div><label Id="icon" for="icon" class="form-label">Från</label>
                <div>
                    <p-calendar
                    [maxDate]="toDate"
                    [(ngModel)]="fromDate"
                    (ngModelChange)="onFromDateChanged()"
                    [showIcon]="true"
                    inputId="icon"
                    class="p-button-datetime"
                    dateFormat="mm/dd/yy"
                    placeholder="mm/dd/yyyy"
                  ></p-calendar>
                </div>
            </div>

        </div>
        <div class="col-2">
            <div><label Id="icon" for="icon" class="form-label">Till</label>
                <div>
                    <p-calendar
                    [minDate]="fromDate"
                    [(ngModel)]="toDate"
                    [showIcon]="true"
                    inputId="icon"
                    [disabled]="!fromDate"
                    (ngModelChange)="onToDateChanged()"
                    class="p-button-datetime"
                    dateFormat="mm/dd/yy"
                    placeholder="mm/dd/yyyy"
                  ></p-calendar>
                    </div>
            </div>
        </div>       
        <div class="col-2">
            <div><label Id="icon" for="icon" class="form-label">Anläggning</label>
                <div>   
                    <p-multiSelect
                    [options]="yards"
                    [(ngModel)]="selectedYards"
                    (ngModelChange)="onYardSelectionChange()"
                    optionLabel="yardName"
                    placeholder="Välj anläggning(ar)"
                    class="w-100"
                    emptyFilterMessage = "Hittas ej"
                  ></p-multiSelect>
                </div>
            </div>
        </div>
        <div class="col-2" *ngIf="!isIndividualCustomer">
            <div><label Id="icon" for="icon" class="form-label">Kostnadsställe</label>
                <div> 
                    <p-multiSelect
                    [options]="costCenters"
                    [(ngModel)]="selectedCostCenters"
                    (ngModelChange)="onCostCenterSelectionChange()"
                    optionLabel="costCenterName"
                    placeholder="Välj kostnadsställe(n)"
                    emptyFilterMessage = "Hittas ej"
                  ></p-multiSelect>
                </div>
            </div>
        </div>
        <div class="align-items-end col-2 d-flex ">
            <button pButton label="Rensa" class="p-button-outlined" icon="pi pi-filter-slash" (click)="clear()"></button>
          </div>
    </div>
    <app-waste-code-grid [pageNumber]="rdPageNumber" [summaryTotals]="ewcGridTotals" (refreshTable)="refreshTable($event)" (exportEvent)="export($event)" [gridData]="ewcGridData" [tblFilters]="ewcTblFilters" [isEWCWasteCodeGrid]="true" ></app-waste-code-grid>
    <app-waste-code-grid [pageNumber]="ewcPageNumber" [summaryTotals]="rdGridTotals" (refreshTable)="refreshTable($event)" (exportEvent)="export($event)" [gridData]="rdGridData" [tblFilters]="rdTblFilters" [isEWCWasteCodeGrid]="false"></app-waste-code-grid>
</div>