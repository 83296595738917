<div class="col-12">
    <p-table [value]="signupRequstCustomers"
        styleClass="mt-2 p-datatable-gridlines p-datatable-md p-datatable-sm" [rowHover]="true">
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th>
                    Customer Type
                </th>
                <th>
                    SSN/ORG
                </th>
                <th>
                    Email
                </th>
                <th>
                    Phone
                </th>
                <th>

                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
                <td>
                    {{rowData.customerType}}
                </td>
                <td>
                    {{rowData.ssnOrg}}
                </td>
                <td>
                    {{rowData.email}}
               </td>
               <td>
                  {{rowData.phone}}
              </td>
                <td>
                    <p-button  (onClick)="getEmailContet(rowData)"> Generate Mapping Request Email</p-button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>

<p-dialog [closable]="true" header="Email Content" [(visible)]="displayEmailConent" [modal]="true" [style]="{width: '50vw'}"
[draggable]="false" [resizable]="false">
    <textarea [readOnly]="true" style="height: 400px;" pInputTextarea [(ngModel)]="emailContent"></textarea>
</p-dialog>