import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppRouterPath, StorageKey } from '../constants'
import { Router } from '@angular/router';
import { TokenResponse } from '../models/auth.model';
import { AuthStatus } from '../models/state.model';
import { Store } from '@ngrx/store';
import { appLocalStorage } from '../helpers/local-storage.helper';
import { SignUpData } from 'src/app/modules/sign-up/models/sign-up.data';
import { DeclineSignupRequest } from 'src/app/modules/sign-up/models/decline-signup-request';
import { resetAuthStatus } from 'src/app/store/actions/app.auth.action';
import { SignUpCustomerRequest } from 'src/app/modules/sign-up/models/signup-customer-request';
import { AcceptSignupRequest } from 'src/app/modules/sign-up/models/accept-signup-request';


const AUTH_API = `${environment.apiUrl}/Authenticate`;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient, private router: Router, private store: Store<AuthStatus>) { }

  authenticated = (): boolean => {
    return !!(appLocalStorage.get(StorageKey.ACCESS_TOKEN) && appLocalStorage.get(StorageKey.REFRESH_TOKEN));
  }

  getAccessToken = (): string => {
    return appLocalStorage.get(StorageKey.ACCESS_TOKEN);
  }

  getRefreshToken = (): string => {
    return appLocalStorage.get(StorageKey.REFRESH_TOKEN);
  }

  login = (username: string, password: string): Observable<any> => {
    return this.http.post(
      `${AUTH_API}/login`,
      {
        username,
        password,
      },
      httpOptions
    );
  }

  refreshToken = (accessToken: string, refreshToken: string) => {
    return this.http.post(
      `${AUTH_API}/refresh-token`,
      {
        accessToken,
        refreshToken
      },
      httpOptions
    );
  }

  register = (username: string, email: string, password: string): Observable<any> => {
    return this.http.post(
      `${AUTH_API}/register`,
      {
        username,
        email,
        password,
      },
      httpOptions
    );
  }

  resetPassword = (email: string, token: string, newPassword: string): Observable<any> => {
    return this.http.post(
      `${AUTH_API}/reset-password`,
      {
        email,
        token,
        newPassword
      },
      httpOptions
    );
  }

  requestResetPassword = (email: string): Observable<any> => {
    return this.http.post(
      `${AUTH_API}/request-reset-password`,
      {
        email
      },
      httpOptions
    );
  }

  saveTokens = (data: TokenResponse): void => {
    appLocalStorage.save(StorageKey.ACCESS_TOKEN, data.token? data.token: data.accessToken);
    appLocalStorage.save(StorageKey.REFRESH_TOKEN, data.refreshToken);
  }

  validateToken = (): any => {
    return this.http.get(
      `${environment.apiUrl}/api/Common/validate-token`
    );
  }

  logout = () => {
    appLocalStorage.clean();
    this.store.dispatch(resetAuthStatus());
    this.router.navigateByUrl(`/${AppRouterPath.login}`);
  }

  validateEmai = (email:string) : Observable<boolean> =>{
    return this.http.post<boolean>(
      `${AUTH_API}/${AppRouterPath.validateEmail}`,
      {
        email
      },
      httpOptions
    );
  }

  signupCustomer = (uignupData:SignUpData): Observable<any> => {
    return this.http.post(
      `${AUTH_API}/register-customer`,uignupData,
      httpOptions
    );
  }

  getEmailContent(data: SignUpCustomerRequest) :Observable<string> {
    return this.http.post(
      `${AUTH_API}/mapping-process-completion-email`, 
      data, 
      { headers: httpOptions.headers, responseType: 'text'}
    )
  }


  declineSignupRequest = (declineData:DeclineSignupRequest): Observable<any> => {
    return this.http.post(
      `${AUTH_API}/decline-signup-request`,declineData,
      httpOptions
    );
  }

  accceptSignupRequest(acceptRequest:AcceptSignupRequest): Observable<any> {
    return this.http.post(
      `${AUTH_API}/accept-signup-request`,acceptRequest,
      httpOptions
    );
  }
}
