import { AppRouterPath } from 'src/app/shared/constants';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { AuthStatus, SelectedProfile } from '../models/state.model';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class LoginAuthGuard implements CanActivate {
  profile: SelectedProfile;
  constructor(
    private authService: AuthService,
    private router: Router,
    private store: Store<AuthStatus>
  ) {
    this.store
      .select((state) => state['authStatus']['selectedProfile'])
      .subscribe((profile: SelectedProfile) => {
        this.profile = profile;
      });
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const appAuthenticated = this.authService.authenticated();
    if (!appAuthenticated) {
      this.router.navigateByUrl(`/${AppRouterPath.login}`);
    }
    return appAuthenticated;
  }
}
