import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { TitleService } from 'src/app/shared/services/title.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {
  
  items!: MenuItem[];

  constructor(private titleService:TitleService) {}

  ngOnInit() {
    this.titleService.setTitle("registrering");
      this.items = [{
              label: 'Kunduppgifter',
              routerLink: 'customer-details',
          },
          {
              label: 'Kundkoppling',
              routerLink: 'customer-mapping'
          }
      ];
      
  }
  
}
