import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './components/dashboard.component';
import { ChartModule} from 'primeng/chart';
import { MultiSelectModule } from 'primeng/multiselect';
import { FormsModule } from '@angular/forms'; 
import { TableModule } from 'primeng/table';

@NgModule({
  declarations: [
    DashboardComponent
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    ChartModule,
    MultiSelectModule,
    FormsModule,
    TableModule,
  ],
  providers: [
    DatePipe,]
})
export class DashboardModule { }
