import { Component, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ChartConfigService } from 'src/app/modules/dashboard/services/chart-config.service';
import { SummaryService } from '../../services/summary.service';

@Component({
  selector: 'app-summary-chart',
  templateUrl: './summary-chart.component.html',
  styleUrls: ['./summary-chart.component.css'],
})
export class SummaryChartComponent {
  @Input() data;
  @Input() tabledata;
  @Input() total;

  chartOptions: any;
  config: any;
  visibleChart: boolean;
  summaryChartSub: Subscription;

  constructor(
    public configService: ChartConfigService,
    public summaryService: SummaryService
  ) {
    
  }

  configuration() {
    this.chartOptions =
      this.config && this.config.dark
        ? this.getDarkTheme()
        : this.getLightTheme();
    this.config = this.configService.config;
  }

  getLightTheme() {
    return {
      plugins: {
        legend: {
          labels: {
            color: '#000000',
          },
        },
      },
    };
  }

  getDarkTheme() {
    return {
      plugins: {
        legend: {
          labels: {
            color: '#000000',
          },
        },
      },
    };
  }
}
