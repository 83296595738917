import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../../shared/services/auth.service';
import { AppRouterPath } from '../../../../shared/constants';
import { Router } from '@angular/router';
import { TokenResponse } from '../../../../shared/models/auth.model';
import { MessageService } from 'primeng/api';
import { Store } from '@ngrx/store';
import { DisplayMessages } from '../../../../shared/models/state.model';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { reviseSelectedProfile, reviseLoginFlowStatus, persistProfile } from 'src/app/store/actions/app.auth.action';
import { displayResetPasswordMessage } from 'src/app/store/actions/app.messages.action';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TitleService } from 'src/app/shared/services/title.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [MessageService]
})
export class LoginComponent implements OnInit {
  roles: any;
  errorMessage: any;
  invalidInputResponse: boolean = false;
  usernameDirty: boolean = false;
  submitted: boolean = false;

  appRouterPath = AppRouterPath;

  loginForm: FormGroup = this.fb.group({
    userName: [null, [Validators.required as any]],
    password: [null, [Validators.required as any]],
  });

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private customerService: CustomerService,
    private messageService: MessageService,
    private router: Router,
    private titleService:TitleService,
    private store: Store<DisplayMessages>) {}

  ngOnInit(): void {
    this.titleService.setTitle("Logga in");
    if (this.authService.authenticated()) {
      this.navigateToView(`/${this.appRouterPath.dashboard}`);
    }

    this.store.select(state => state['displayMessages']['resetPassword']).subscribe((value: boolean) => {
      if(value) {
        setTimeout(() => {
          this.displayResetPasswordStatus();
        }, 500);
      }
    });

    this.loginForm.get('userName')?.valueChanges.subscribe(value => {
      this.loginForm.get('password').setErrors(null);
    });

    this.loginForm.get('password')?.valueChanges.subscribe(value => {
      this.loginForm.get('userName').setErrors(null);
    });
  }

  onSubmit(): void {
    this.authService.login(this.loginForm.controls['userName'].value, this.loginForm.controls['password'].value).subscribe({
      next: (data: TokenResponse) => {
        this.authService.saveTokens(data);
        this.customerService.isSingleCusomerAndSingleCompany().subscribe({
          next: (data: any) => {
            if(data['isOneCustomerCompany']) {
              this.store.dispatch(reviseSelectedProfile({
                customerId: data['smartCustomerId'],
                companyId: data['smartCompanyId'],
                customerTypeId:data['purchasingCustomerTypeId'],
                mainCustomerId: data['smartCustomerId'],
              }));
              this.store.dispatch(reviseLoginFlowStatus(true));
              this.store.dispatch(persistProfile());
              this.navigateToView(`/${AppRouterPath.dashboard}`);
            } else {
              this.navigateToView(`/${this.appRouterPath.selectCustomer}`);
            }
          }
        });
      },
      error: err => {
        this.loginForm.controls['userName'].setErrors({'InvalidCredentials': true});
        this.loginForm.controls['password'].setErrors({'InvalidCredentials': true});
      }
    });
  }

  aknowledgeError = () => {
    this.invalidInputResponse = false;
  }

  navigateToView = (url: string) => {
    this.router.navigateByUrl(url);
  }

  displayResetPasswordStatus = () => {
    this.messageService.add({  severity:'success', summary:'Återställ lösenord', detail:'Lösenordet har återställts!'});
    setTimeout(() => {
      this.messageService.clear('reset-pwd');
      this.store.dispatch(displayResetPasswordMessage(false));
    }, 10000);
  }
}
