<div class="container-fluid pb-1 px-5 mt-5 pt-4">
  <div class="row mt-3">
    <div class="col-sm-12">
      <h5 class="fw-bold">Material</h5>
    </div>
  </div>
  <div class="row bg-white p-3 shadow-sm my-3 justify-content-start">
    <div class="col-2">
      <div>
        <label Id="icon" for="icon" class="form-label">Från</label>
        <div>
          <p-calendar
            [maxDate]="toDate"
            [(ngModel)]="fromDate"
            (ngModelChange)="onFromDateChanged()"
            [showIcon]="true"
            inputId="icon"
            class="p-button-datetime"
            dateFormat="mm/dd/yy"
            placeholder="mm/dd/yyyy"
          ></p-calendar>
        </div>
      </div>
    </div>
    <div class="col-2">
      <div>
        <label Id="icon" for="icon" class="form-label">Till</label>
        <div>
          <p-calendar
            [minDate]="fromDate"
            [(ngModel)]="toDate"
            [disabled]="!fromDate"
            (ngModelChange)="onToDateChanged()"
            [showIcon]="true"
            inputId="icon"
            class="p-button-datetime"
            dateFormat="mm/dd/yy"
            placeholder="mm/dd/yyyy"
          ></p-calendar>
        </div>
      </div>
    </div>
    <div class="col-2">
      <div>
        <label Id="icon" for="icon" class="form-label">Anläggning</label>
        <div>
          <p-multiSelect
            [options]="yards"
            [(ngModel)]="selectedYards"
            (ngModelChange)="onYardSelectionChange()"
            optionLabel="yardName"
            placeholder="Välj anläggning(ar)"
            class="w-100"
            emptyFilterMessage = "Hittas ej"
          ></p-multiSelect>
        </div>
      </div>
    </div>
    <div class="col-2"  *ngIf="!isIndividualCustomer">
      <div>
        <label Id="icon" for="icon" class="form-label">Kostnadsställe</label>
        <div>
          <p-multiSelect
            [options]="costCenters"
            [(ngModel)]="selectedCostCenters"
            (ngModelChange)="onCostCenterSelectionChange()"
            optionLabel="costCenterName"
            placeholder="Välj kostnadsställe(n)"
            emptyFilterMessage = "Hittas ej"
          ></p-multiSelect>
        </div>
      </div>
    </div>
    <div class="align-items-end col-2 d-flex mb-1">
      <button pButton label="Rensa" class="p-button-outlined" icon="pi pi-filter-slash" (click)="clear()"></button>
    </div>
    <div class="col-2 ms-auto d-flex align-items-end justify-content-end">
      <div>
        <a (click)="export()" class="btn btn-light shadow-sm p-2 me-3 small">
          <i class="pi pi-file-excel btn-operations color-primary"></i><br />
          <label class="form-label small mb-0">Exportera</label>
        </a>

        <a (click)="getChartData()" class="btn btn-light shadow-sm p-2">
          <i class="pi pi-chart-pie btn-operations color-primary"></i
          ><br /><label class="form-label small mb-0">Graf</label>
        </a>
      </div>
    </div>
  </div>
  <div class="row bg-white p-3 shadow-sm my-3 justify-content-start">
    <p-table
      #dt1
      [value]="summaryData.results"
      selectionMode="single"
      dataKey="id"
      styleClass="mt-2 p-datatable-gridlines p-datatable-sm"
      [rowHover]="true"
      [customSort]="true"
      sortField="{{filters.orderBy}}" 
      [sortOrder]="sortOrder"
      [rows]="10"
      stateStorage="local"
      stateKey="statedemo-local"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="Visar {first} till {last} av {totalRecords} rader"
      [rowsPerPageOptions]="[10, 25, 50]"
      (sortFunction)="customSort($event)"
    >
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="ArticleGroupName">
            Huvudgrupp <p-sortIcon field="ArticleGroupName"></p-sortIcon>
          </th>
          <th pSortableColumn="ArticleSubGroupName">
            Undergrupp <p-sortIcon field="ArticleSubGroupName"></p-sortIcon>
          </th>
          <th pSortableColumn="ArticleNumber">
            Artikel<p-sortIcon field="ArticleNumber"></p-sortIcon>
          </th>
          <th>
            Vikt <small>(kg)</small> 
          </th>
          <th>
            Belopp (ex. moms)
          </th>
          <th>
            Pris <small>(kr/kg)</small>
          </th>
        </tr>
        <tr>
          <th>
            <p-multiSelect
              [options]="articleGroups"
              [(ngModel)]="selectedArticleGroups"
              (ngModelChange)="onArticleGroupSelectionChange()"
              optionLabel="articleGroupName"
              placeholder="Välj huvudgrupp(er)"
              [appendTo]="dt1"
              emptyFilterMessage = "Hittas ej"
            ></p-multiSelect>
          </th>
          <th>
            <p-multiSelect
              [options]="articleSubGroups"
              [(ngModel)]="selectedArticleSubGroups"
              (ngModelChange)="onArticleSubGroupSelectionChange()"
              optionLabel="articleSubGroupName"
              placeholder="Välj undergrupp(er)"
              [appendTo]="dt1"
              emptyFilterMessage = "Hittas ej"
            ></p-multiSelect>
          </th>
          <th>
            <input type="text" pInputText placeholder="Ange artikelnummer eller beskrivning" (keyup.enter)="onChangedArticleNumberFilter()" [(ngModel)]="filters.articleNumber">
          </th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-element>
        <tr>
          <td>{{element.articleGroupName}}</td>
          <td>{{element.articleSubGroupName}}</td>
          <td>{{element.articleNumber}} ({{element.articleName}})</td>
          <td class="text-end">{{element.sum_Weight| number: '1.0-0':'lang'}}</td>
          <td class="text-end">{{element.sum_VATExclusiveAmount  | currency:'SEK':'symbol':'1.0-0':'lang'}}</td>
          <td class="text-end">{{element.price | currency:'SEK':'symbol':'1.3-3':'lang'}}</td>
        </tr>
      </ng-template>
      <ng-template *ngIf="summaryData.results" pTemplate="footer">
        <tr>
            <td colspan="3" class="text-left">Summa</td>
            <td class="text-end">{{summaryTotals.weight | number: '1.0-0':'lang'}}</td>
            <td class="text-end">{{summaryTotals.vatExclusiveAmount | currency:'SEK':'symbol':'1.0-0':'lang'}}</td>
            <td></td>
        </tr>
    </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="7" class="text-center">Ingen information hittad.</td>
        </tr>
      </ng-template>
    </p-table>
    <p-paginator [showCurrentPageReport] = "true" currentPageReportTemplate="Visar {first} till {last} av {totalRecords} rader" (onPageChange)="onPageChange($event)" [first]="pageNumber" [rows]="10" [totalRecords]="summaryData.totalNumberOfRecords" [rowsPerPageOptions]="[10, 20, 30]"></p-paginator>
  </div>
</div>

<p-dialog [modal]="true" header="Vikt per materialgrupp" [(visible)]="visibleChart" [style]="{ 'width': '50%' }" >
    <app-summary-chart [total]="chartTblDataTotal" [tabledata]="chartTblData" [data]="chartData" width="auto"> </app-summary-chart>
</p-dialog>
