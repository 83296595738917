import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './components/login/login.component';
import { PasswordModule } from 'primeng/password';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { HttpClientModule } from '@angular/common/http';
import { DropdownModule } from 'primeng/dropdown';
import { SelectCustomerComponent } from './components/select-customer/select-customer.component';
import { ToastModule } from 'primeng/toast';

@NgModule({
  declarations: [LoginComponent, SelectCustomerComponent],
  imports: [
    CommonModule,
    LoginRoutingModule,
    PasswordModule,
    FormsModule,
    InputTextModule,
    HttpClientModule,
    DropdownModule,
    ToastModule,
    ReactiveFormsModule
  ]
})
export class LoginModule { }
