import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CustomerType } from '../models/customer-type';
import { catchError, Observable,of } from 'rxjs';
import { Company } from '../models/company';
import { SignUpCustomerRequest } from '../models/signup-customer-request';

@Injectable({
  providedIn: 'root'
})
export class SignUpService {

  constructor(private http:HttpClient) { }

  rootURL = environment.apiUrl;

  getCustomerTypes():Observable<CustomerType[]>  {
    return this.http.get<CustomerType[]>(this.rootURL + '/memberdata/PurchasingCustomerTypes')
    .pipe(
      catchError(this.handleError<CustomerType[]>('getCustomerTypes', []))
    );
  }

  getCompanies(customerTypeId :number, ssnOrg:string):Observable<Company[]>  {
    return this.http.get<Company[]>(this.rootURL + '/memberdata/Company/'+customerTypeId+"/"+ssnOrg)
    .pipe(
      catchError(this.handleError<Company[]>('getCompanies', []))
    );;
  }

  validateEmail(email:string):Observable<boolean>{
    return this.http.post<boolean>(this.rootURL+'/authenticate/validate-email', {email:''});
    //.pipe(catchError(this.handleError<boolean>('getCompanies', null)));
  }

  getCustomerSignUpRequests() :Observable<SignUpCustomerRequest[]>{
    return this.http.get<SignUpCustomerRequest[]>(this.rootURL + '/customer/get-signup-customers')
    .pipe(
      catchError(this.handleError<SignUpCustomerRequest[]>('getCustomerSignUpRequests', []))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
  
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
  
      // TODO: better job of transforming error for user consumption
      //this.log(`${operation} failed: ${error.message}`);
  
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
}

translateCustomerTypes(customerTypes:CustomerType[]):CustomerType[]{
  for(var res of customerTypes){
    if(res.id == 1){
      res.name = 'Företag';
    }

    if(res.id == 2){
      res.name = 'Privatperson';
    }
  }
  return customerTypes;
}
}
