import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/shared/helpers/auth.guard';
import { AppRouterPath } from '../../shared/constants';
import { WasteCodeComponent } from './component/waste-code.component';

const routes: Routes = [{ path: AppRouterPath.wasteCode, component: WasteCodeComponent, canActivate: [AuthGuard]}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WasteCodeRoutingModule { }
